import Box from '@mui/material/Box';
import * as React from 'react';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import WaitLoading from '../components/WaitLoading';
import { apiBaseUrl } from '../consts';
import { sessionState } from '../state';

export default function Logout() {
  const [fetchStage, setFetchStage] = useState('unloaded');
  const setSessionData = useSetRecoilState(sessionState);

  React.useEffect(() => {    
    if (fetchStage === 'unloaded') {
      fetch(`${apiBaseUrl}/logout.php`, {
        method: 'POST',
        credentials: 'include'
      })
        .then(response => response.json())
        .then(
          response => {
            setSessionData(null);
            setFetchStage('loaded');
          }
        );
      setFetchStage('loading');
    }
  }, [ fetchStage ]);

  if (fetchStage === 'loaded') {
    return (
      <Navigate to='/login' />
    );
  } else {
    return (<Box sx={{
      backgroundColor: (theme) =>
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[900],
      position: 'fixed',
      width: '100vw',
      height: '100vh'
    }}>
      <WaitLoading />
    </Box>);
  }
}
