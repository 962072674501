import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ConsolePage from '../../components/ConsolePage';
import ConsolePageBody from '../../components/ConsolePageBody';
import WaitLoading from '../../components/WaitLoading';
import { apiBaseUrl, modalBoxStyle, secHeaderKey } from '../../consts';
import { activeSessionState } from '../../state';
import { isValidEmailAddress, isValidPhoneNumber } from '../../utils/string';

export default function NewGuest() {
  const navigate = useNavigate();

  const [givenName, setGivenName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('+39');
  const [phone2, setPhone2] = useState('');
  const [email, setEmail] = useState('');
  const [email2, setEmail2] = useState('');
  const [note, setNote] = useState('');

  const [pageResult, setPageResult] = useState<{
    title: string;
    message: string;
  } | null>(null);

  const [validateGivenNameSw, setValidateGivenNameSw] = useState(false);
  const [validateSurnameSw, setValidateSurnameSw] = useState(false);
  const [validatePhoneSw, setValidatePhoneSw] = useState(false);
  const [validatePhone2Sw, setValidatePhone2Sw] = useState(false);
  const [validateEmailSw, setValidateEmailSw] = useState(false);
  const [validateEmail2Sw, setValidateEmail2Sw] = useState(false);

  const smallDevice = !useMediaQuery('(min-width:600px)');

  const validateGivenName = (sw = validateGivenNameSw) => {
    if (sw) {
      const len = givenName.length;
      if (len === 0) {
        return 'Required field';
      }
    }

    return '';
  }

  const validateSurname = (sw = validateSurnameSw) => {
    if (sw) {
      const len = surname.length;
      if (len === 0) {
        return 'Required field';
      }
    }

    return '';
  }

  const validatePhone = (sw = validatePhoneSw) => {
    if (sw) {
      const len = phone.length;
      if (len === 0) {
        return 'Required field';
      }
      
      if (phone[0] !== '+') {
        return 'Phone number must start with the + character';        
      }

      if (!isValidPhoneNumber(phone)) {
        return 'Invalid phone number (must be in international format)';
      }
    }

    return '';
  }

  const validatePhone2 = (sw = validatePhone2Sw) => {
    if (sw) {
      const len = phone2.length;
      if (len === 0) {
        return 'Required field';
      }
      
      if (phone2 !== phone) {
        return `Phone 2 does not match`;
      }
    }

    return '';
  }

  const validateEmail = (sw = validateEmailSw) => {
    if (sw) {
      const len = email.length;
      if (len === 0) {
        return 'Required field';
      }

      if (!isValidEmailAddress(email)) {
        return 'Invalid email address';
      }
    }

    return '';
  }

  const validateEmail2 = (sw = validateEmail2Sw) => {
    if (sw) {
      const len = email2.length;
      if (len === 0) {
        return 'Required field';
      }
      
      if (email2 !== email) {
        return `Email 2 does not match`;
      }
    }

    return '';
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setValidateGivenNameSw(true);
    setValidateSurnameSw(true);
    setValidatePhoneSw(true);
    setValidatePhone2Sw(true);
    setValidateEmailSw(true);
    setValidateEmail2Sw(true);

    if (validateGivenName(true)) {
      return;
    }
    if (validateSurname(true)) {
      return;
    }
    if (validatePhone(true)) {
      return;
    }
    if (validatePhone2(true)) {
      return;
    }
    if (validateEmail(true)) {
      return;
    }
    if (validateEmail2(true)) {
      return;
    }

    setExecuteNewGuest(true);
  };  

  const handleClose = () => {
    navigate(`/console/my-guests`);
  }

  const handleShowResultClose = () => {
    if (pageResult!.title === 'Success') {
      navigate(`/console/my-guests`);
    } else {
      setPageResult(null);
    }
  }

  const givenNameError = validateGivenName();
  const surnameError = validateSurname();
  const phoneError = validatePhone();
  const phone2Error = validatePhone2();
  const emailError = validateEmail();
  const email2Error = validateEmail2();

  const handleGivenNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGivenName(event.target.value);
    setValidateGivenNameSw(true);
  };

  const handleSurnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSurname(event.target.value);
    setValidateSurnameSw(true);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
    setValidatePhoneSw(true);
  };

  const handlePhone2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone2(event.target.value);
    setValidatePhone2Sw(true);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setValidateEmailSw(true);
  };

  const handleEmail2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail2(event.target.value);
    setValidateEmail2Sw(true);
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const [executeNewGuest, setExecuteNewGuest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sessionData = useRecoilValue(activeSessionState);

  useEffect(() => {
    if (executeNewGuest) {
      fetch(`${apiBaseUrl}/new-guest.php`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          [secHeaderKey]: sessionData.sec
        },        
        body: JSON.stringify({
          givenName, surname,
          phone, email,
          note
        })
      })
        .then(response => {
          if (response.status === 401) {
            navigate('/login');
          } else if(response.status!==200) {
            throw Error(`${response.status} - ${response.statusText}`);
          } else {
            return response.json();
          }
        })
        .then(
          response => {
            setIsLoading(false);
            if (response.success) {
              setPageResult({
                title: 'Success',
                message: 'New guest has been created.'
              });
            } else {
              if (typeof response.message === 'string') {
                setPageResult({
                  title: 'Error',
                  message: `An error has occurred: ${response.message}`
                });
              } else {
                setPageResult({
                  title: 'Error',
                  message: `An unknown error has occurred.`
                });
              }
              console.error('New my-guest error', response);
            }
          },
          errorData => {
            setIsLoading(false);
            setPageResult({
              title: 'Server Error',
              message: `An internal server error has occurred.`
            });
            console.error('New my-guest server error', errorData);
          }
        );
      setExecuteNewGuest(false);
      setIsLoading(true);
    }
  }, [ executeNewGuest ]);

  const sendUsernameTo = validateEmailSw && (emailError === '') && validateEmail2Sw && (email2Error === '') ? email : '';
  const sendPasswordTo = validatePhoneSw && (phoneError === '') && validatePhone2Sw && (phone2Error === '') ? phone : '';

  return (
    <ConsolePage title='New Guest'>
      <ConsolePageBody>
        <Box component='form' autoComplete='off' noValidate onSubmit={handleSubmit}>
          <Grid container>
            <Grid item lg={8}>
              <Paper sx={{ p: 2 }} elevation={3} >              
                <TextField id='txtGivenName' label='Given Name *' variant='filled'
                  fullWidth autoFocus={!smallDevice}
                  value={givenName}
                  onChange={handleGivenNameChange}
                  helperText={givenNameError}
                  error={givenNameError !== ''}
                  inputProps={{ maxLength: 64 }}
                />

                <TextField id='txtSurname' label='Surname *' variant='filled'
                  fullWidth sx={{ mt: 2 }}
                  value={surname}
                  onChange={handleSurnameChange}
                  helperText={surnameError}
                  error={surnameError !== ''}
                  inputProps={{ maxLength: 64 }}
                />   
                
                <TextField id='txtPhone' label='Phone *' variant='filled'
                  fullWidth sx={{ mt: 5 }}
                  value={phone}
                  onChange={handlePhoneChange}
                  helperText={phoneError}
                  error={phoneError !== ''}
                  inputProps={{ maxLength: 19 }}
                /> 
                <TextField id='txtPhone2' label='Phone (retype) *' variant='filled'
                  fullWidth sx={{ mt: 2 }}
                  value={phone2}
                  onChange={handlePhone2Change}
                  helperText={phone2Error}
                  error={phone2Error !== ''}
                  inputProps={{ maxLength: 19 }}
                /> 

                <TextField id='txtEmail' label='Email *' variant='filled'
                  fullWidth sx={{ mt: 5 }}
                  value={email}
                  onChange={handleEmailChange}
                  helperText={emailError}
                  error={emailError !== ''}
                  inputProps={{ maxLength: 128 }}
                /> 
                <TextField id='txtEmail2' label='Email (retype) *' variant='filled'
                  fullWidth sx={{ mt: 2 }}
                  value={email2}
                  onChange={handleEmail2Change}
                  helperText={email2Error}
                  error={email2Error !== ''}
                  inputProps={{ maxLength: 128 }}
                /> 

                <TextField id='txtNote' label='Notes' variant='filled'
                  fullWidth sx={{ mt: 5 }}
                  value={note}
                  onChange={handleNoteChange}
                  inputProps={{ maxLength: 164 }}
                  multiline minRows={2}
                /> 

                <Box mt={5}>
                  <InfoIcon sx={{float: 'left'}} color='info' fontSize='large'  />
                  <Typography component='div' variant='body2' ml={6}>
                    <ul style={{paddingLeft: '20px'}}>
                      <li>
                        Username will be sent to email address <code><strong>{ sendUsernameTo }</strong></code> 
                      </li>
                      <li>
                        Password will be sent via SMS to phone number <code><strong>{ sendPasswordTo }</strong></code>                         
                      </li>
                    </ul>
                  </Typography>
                </Box>
              </Paper>

              <Box sx={{ p: 2, mt: 1 }}>
                <Button aria-label='save' variant='contained' sx={{ mr: 3, mt: 2 }} type='submit' fullWidth={smallDevice}>
                  <CheckIcon sx={{mr: 2}} /> Save
                </Button>
                <Button aria-label='cancel' variant='outlined' sx={{ mt: 2 }} onClick={handleClose} fullWidth={smallDevice}>
                  <ClearIcon sx={{mr: 2}} /> Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Modal
          open={pageResult !== null}
          onClose={handleShowResultClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box sx={modalBoxStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              { pageResult?.title }
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            { pageResult?.message }
            </Typography>
            <Button aria-label='continue' variant='contained' onClick={handleShowResultClose} sx={{mt: 3}}>
              <PlayArrowIcon sx={{ mr: 2 }} /> Continue
            </Button>
          </Box>
        </Modal>    
        {isLoading && <WaitLoading />}
      </ConsolePageBody>
    </ConsolePage>
  );
}