import AddIcon from '@mui/icons-material/Add';
import BackspaceIcon from '@mui/icons-material/Backspace';
import PageviewIcon from '@mui/icons-material/Pageview';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ConsolePage from '../../components/ConsolePage';
import ConsolePageBody from '../../components/ConsolePageBody';
import { apiBaseUrl, secHeaderKey, userLevels } from '../../consts';
import { UserData } from '../../data';
import { activeSessionState, permissionSelector } from '../../state';
import { adaptAffiliation, adaptUserType, formatUserLevel, getUserLevel, getVlanDescName } from '../../utils/adapters';

interface ViewButtonProps {
  id: string;
  hasFocus: boolean;
  onClick: (id: string) => void;
}

const ViewButton = (props: ViewButtonProps) => {
  const openUser = () => {
    props.onClick(props.id);
  }

  return (
    <Button aria-label='view'
      variant='outlined'
      size='small'
      color='secondary'
      tabIndex={props.hasFocus ? 0 : -1} onClick={openUser} >
      <PageviewIcon sx={{mr: 1}} /> Details...
    </Button>
  );
}

function WaitSkeleton() {
  return (
    <>
      <Skeleton animation="wave" variant='text' />
      <Skeleton animation="wave" variant='text' />
      <Skeleton animation="wave" variant='text' />
    </>
  );
}

function Status(props: {
  enabled: boolean,
  expiration: string
}) {
  if (props.enabled) {
    return (<Typography component='p' variant='subtitle2' color='success.main'>ACTIVE</Typography>)
  } else {
    return (<Typography component='p' variant='subtitle2' color='error'>DISABLED ON <strong>{props.expiration}</strong></Typography>)
  }
}

export default function UserList() {
  const navigate = useNavigate();

  const smallDevice = !useMediaQuery('(min-width:600px)');

  const sessionData = useRecoilValue(activeSessionState);
  const permissionData = useRecoilValue(permissionSelector);

  const [users, setUsers] = useState<UserData[] | null>(null);
  const [searchableUsers, setSeachableUsers] = useState <{ sUser: UserData, user: UserData }[]>([]);
  const [userTypeList, setUserTypeTypeList] = useState<string[]>([]);
  const [affiliationList, setAffiliationList] = useState<string[]>([]);

  const [userTypeFilter, setUserTypeFilter] = useState('');
  const [affiliationFilter, setAffiliationFilter] = useState('');
  const [levelFilter, setLevelFilter] = useState('');
  const [txtFilter, setTxtFilter] = useState('');

  let filteredUsers = [] as UserData[]; 
  if (users !== null) {
    if (userTypeFilter !== '' || affiliationFilter !== '' || levelFilter !== '' || txtFilter !== '') {
      const keys = txtFilter.toLowerCase().split(/(\s+)/).filter(s => s.trim() !== '');
      const totKeys = keys.length;
      if (userTypeFilter !== '' || affiliationFilter !== '' || levelFilter !== '' || totKeys > 0) {
        filteredUsers = searchableUsers.filter(({ sUser }) => {
          if (userTypeFilter !== '') {
            if (sUser.userType !== userTypeFilter) {
              return false;
            }
          } 
          if (affiliationFilter !== '') {
            if (sUser.affiliation !== affiliationFilter) {
              return false;
            }
          } 
          if (levelFilter !== '') {
            if (sUser.level !== levelFilter) {
              return false;
            }
          } 
          
          let totKeyFound = 0;
          for (let i = 0; i < totKeys; i++) {
            const key = keys[i];
            if (sUser.displayName.indexOf(key) !== -1) {
              totKeyFound += 1;
              continue;
            }
            if (sUser.userName.indexOf(key) !== -1) {
              totKeyFound += 1;
              continue;
            }   
            if (sUser.email.indexOf(key) !== -1) {
              totKeyFound += 1;
              continue;
            }   
            if (sUser.phone.indexOf(key) !== -1) {
              totKeyFound += 1;
              continue;
            }
            if (permissionData.canManageMultipleVLans) {
              if (sUser.vlanDescName.indexOf(key) !== -1) {
                totKeyFound += 1;
                continue;
              }  
            }
          }
          return totKeyFound === totKeys;
        }).map(({ user }) => user);    
      } else {        
        filteredUsers = users;  
      }
    } else {
      filteredUsers = users;
    }
  }


  const handleUserTypeFilterChange = (event: SelectChangeEvent) => {
    localStorage.setItem('users.last.userTypeFilter', event.target.value);
    setUserTypeFilter(event.target.value);
  };

  const handleAffiliationFilterChange = (event: SelectChangeEvent) => {
    localStorage.setItem('users.last.affiliationFilter', event.target.value);
    setAffiliationFilter(event.target.value);
  };

  const handleLevelFilterChange = (event: SelectChangeEvent) => {
    localStorage.setItem('users.last.levelFilter', event.target.value);
    setLevelFilter(event.target.value);
  };

  const handleTxtFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('users.last.txtFilter', event.target.value);
    setTxtFilter(event.target.value);
  };


  const handleResetFilters = () => {
    localStorage.removeItem('users.last.userTypeFilter');
    localStorage.removeItem('users.last.affiliationFilter');
    localStorage.removeItem('users.last.levelFilter');
    localStorage.removeItem('users.last.txtFilter');

    setUserTypeFilter('');
    setAffiliationFilter('');
    setLevelFilter('');
    setTxtFilter('');
  };

  const handleNewUser = () => {
    navigate(`/console/users/new`);
  };

  const handleOpenUser = (id: string) => {
    const user = users!.find(user => user.id === id);
    navigate(`${user!.userName}`);
  };

  let extraCols: GridColDef[] = [];

  if (permissionData.canManageAllUsers) {
    extraCols = [
      { field: 'displayName', headerName: 'Name', width: 200, flex: 1 },
      { field: 'affiliation', headerName: 'Affiliation', width: 120 },
      { field: 'userType', headerName: 'Type', width: 180 },
      { field: 'level', headerName: 'Level', width: 180 },
      { field: 'vlan', headerName: 'VLAN', width: 60 },
    ];
  } else if (permissionData.canManageMultipleVLans) {
    extraCols = [
      { field: 'displayName', headerName: 'Name', width: 200 },
      { field: 'email', headerName: 'Email', width: 350, flex: 1 },
      { field: 'userType', headerName: 'Type', width: 180 },
      { field: 'vlanDescName', headerName: 'VLAN', width: 110 },
    ];
  } else {
    extraCols = [
      { field: 'displayName', headerName: 'Name', width: 200 },
      { field: 'email', headerName: 'Email', width: 350, flex: 1 },
      { field: 'userType', headerName: 'Type', width: 180 },
    ];
  }

  const cols: GridColDef[] = [
    { field: 'act', headerName: '', width: 145, renderCell: (params: GridRenderCellParams<string>) => (
      <ViewButton id={params.id.toString()} hasFocus={params.hasFocus} onClick={handleOpenUser} />
    )},  
    { field: 'userName', headerName: 'User', width: 180 },
    ...extraCols,
    { field: 'createdOn', headerName: 'Creation Time', width: 200 },
    { field: 'expiration', headerName: 'Status', width: 200, renderCell: (params: GridRenderCellParams<string>) => (
      <Status enabled={params.row.enabled} expiration={params.value!} />
    ), },
  ];

  useEffect(() => {
    const lastUserTypeFilter = localStorage.getItem('users.last.userTypeFilter');
    if (lastUserTypeFilter !== null) {
      setUserTypeFilter(lastUserTypeFilter);
    }

    const lastffiliationFilter = localStorage.getItem('users.last.affiliationFilter');
    if (lastffiliationFilter !== null) {
      setAffiliationFilter(lastffiliationFilter);
    }

    const lastLevelFilter = localStorage.getItem('users.last.levelFilter');
    if (lastLevelFilter !== null) {
      setLevelFilter(lastLevelFilter);
    }

    const lastTxtFilter = localStorage.getItem('users.last.txtFilter');
    if (lastTxtFilter !== null) {
      setTxtFilter(lastTxtFilter);
    }

    setTimeout(() => {
      fetch(`${apiBaseUrl}/users.php`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          [secHeaderKey]: sessionData.sec
        }
      })
        .then(response => {
          if (response.status === 401) {
            navigate('/login');
          } else if(response.status!==200) {
            throw Error(`${response.status} - ${response.statusText}`);
          } else {
            return response.json();
          }
        })
        .then(
          response => {
            if (response.success) {
              const users: UserData[] = response.users;
              users.forEach(user => {
                (user as any).affiliation = adaptAffiliation(user.affiliation);
                (user as any).userType = adaptUserType(user.userType);
                (user as any).level = formatUserLevel(getUserLevel(user));
                (user as any).vlanDescName = getVlanDescName(user.vlan);
              });             
              setUsers(users);
              const searchableUsers = users.map(user => {
                return {
                  sUser: {
                    ...user,
                    displayName: user.displayName.toLowerCase(),
                    userName: user.userName.toLowerCase(),
                    email: user.email.toLowerCase(),
                    phone: user.phone.toLowerCase(),
                    vlanDescName: user.vlanDescName.toLowerCase()
                  }, user
                }
              });   
              setSeachableUsers(searchableUsers);

              const userTypeList = Array.from(new Set(users.map(user => user.userType))).sort();
              setUserTypeTypeList(userTypeList);

              const affiliationList = Array.from(new Set(users.map(user => user.affiliation))).sort();
              setAffiliationList(affiliationList);
            } else {
              console.error('Error loading users.');
            }
        });  
    }, 100);
  }, []); 

  return (
    <ConsolePage title='Users'>
      <ConsolePageBody>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%'}}>
          <Paper sx={{ p: 2, flexGrow: 1 }} elevation={3} > 
            {users === null && <WaitSkeleton />}
            {users !== null && (
              <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%'}}>
                <Box sx={{ display: 'flex', flexDirection: 'row'}}>
                  <TextField id='txtFilter' label={
                    permissionData.canManageMultipleVLans ? 'Username, Name, Email, Phone or VLAN' : 'Username, Name, Email or Phone'
                   } variant='outlined' sx={{ mb: 2, flexGrow: 1 }} autoFocus={!smallDevice}
                  value={txtFilter} onChange={handleTxtFilterChange}
                  />
                  
                  {permissionData.canManageAllUsers && (<FormControl sx={{ width: 140, ml: 2, display: { xs: 'none', lg: 'flex' } }}>
                    <InputLabel id='labelAffiliation'>Affiliation</InputLabel>
                    <Select
                      labelId='labelAffiliation'
                      id='selectAffiiliation'
                      value={affiliationFilter}
                      label='Affiliation'
                      onChange={handleAffiliationFilterChange}>
                      <MenuItem value=''>-- No Filter --</MenuItem>
                      {affiliationList.map(aff => (
                        <MenuItem key={aff} value={aff}>{aff}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>)}
                  
                  <FormControl sx={{ width: 220, ml: 2, display: { xs: 'none', lg: 'flex' } }}>
                    <InputLabel id='labelUserType'>Type</InputLabel>
                    <Select
                      labelId='labelUserType'
                      id='selectUserType'
                      value={userTypeFilter}
                      label='Type'
                      onChange={handleUserTypeFilterChange}>
                      <MenuItem value=''>-- No Filter --</MenuItem>
                      {userTypeList.map(typ => (
                        <MenuItem key={typ} value={typ}>{typ}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {permissionData.canManageAllUsers && (<FormControl sx={{ width: 180, ml: 2, display: { xs: 'none', lg: 'flex' } }}>
                    <InputLabel id='labelLevel'>Level</InputLabel>
                    <Select
                      labelId='labelLevel'
                      id='selectLevel'
                      value={levelFilter}
                      label='Type'
                      onChange={handleLevelFilterChange}>
                      <MenuItem value=''>-- No Filter --</MenuItem>
                      {userLevels.map(lev => (
                        <MenuItem key={lev} value={lev}>{lev}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  )}

                  <Button aria-label='reset-filters' variant='text' sx={{ml: 2, mb: 2, display: { xs: 'none', sm: 'flex' }}} onClick={handleResetFilters}>
                    <BackspaceIcon sx={{mr: 2}} /> Reset filters
                  </Button> 
                </Box>
                <DataGrid rows={filteredUsers} columns={cols} initialState={{
                sorting: {
                  sortModel: [{ field: 'createdOn', sort: 'desc' }],
                },
              }} sx={{ flexGrow: 1 }} />
              </Box>
             )}
          </Paper>
          <Box sx={{ p: 2, mt: 3 }}>  
            <Button aria-label='new-user' variant='contained' sx={{mr: 3}} onClick={handleNewUser}>
              <AddIcon sx={{mr: 2}} /> New User
            </Button>        
          </Box>
        </Box>
      </ConsolePageBody>
    </ConsolePage>
  );
}