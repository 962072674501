import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ConsolePage from '../../components/ConsolePage';
import ConsolePageBody from '../../components/ConsolePageBody';
import WaitLoading from '../../components/WaitLoading';
import { apiBaseUrl, modalBoxStyle, multipleVLanAffiliationMap, secHeaderKey, UserOrigin, vlanNames } from '../../consts';
import { CardsUserData } from '../../data';
import { activeSessionState, permissionSelector } from '../../state';
import { adaptUserType, getCardsUserPhoto, getDateFromDT, getVlanDescName } from '../../utils/adapters';
import { isValidEmailAddress, isValidLocalPhoneNumber } from '../../utils/string';

const stepsWithoutVLan = ['Select Origin', 'Confirm Identity', 'Enter User Details'];
const stepsWithVLan = ['Select Origin', 'Confirm Identity', 'Enter User Details', 'Select VLAN'];

export default function NewUser() {
  const navigate = useNavigate();

  const smallDevice = !useMediaQuery('(min-width:600px)');

  const [cardsUsers, setCardsUsers] = useState<CardsUserData[] | null>(null);

  const [userOrigin, setUserOrigin] = useState('');
  const [cardsUserId, setCardsUserId] = useState('');
  const [externalDocIdFileName, setExternalDocIdFileName] = useState('');
  const [externalDocIdKey, setExternalDocIdKey] = useState('');
  const [noDocId, setNoDocId] = useState(false);

  const [givenName, setGivenName] = useState('');
  const [surname, setSurname] = useState('');
  const [phone, setPhone] = useState('+39');
  const [email, setEmail] = useState('');
  const [email2, setEmail2] = useState('');
  const [note, setNote] = useState('');

  const [vlan, setVlan] = useState('');

  const [pageResult, setPageResult] = useState<{
    title: string;
    message: string;
    onClose: () => void
  } | null>(null);

  const [validateGivenNameSw, setValidateGivenNameSw] = useState(false);
  const [validateSurnameSw, setValidateSurnameSw] = useState(false);
  const [validatePhoneSw, setValidatePhoneSw] = useState(false);
  const [validateEmailSw, setValidateEmailSw] = useState(false);
  const [validateEmail2Sw, setValidateEmail2Sw] = useState(false);

  const validateGivenName = (sw = validateGivenNameSw) => {
    if (sw) {
      const len = givenName.length;
      if (len === 0) {
        return 'Required field';
      }
    }

    return '';
  }

  const validateSurname = (sw = validateSurnameSw) => {
    if (sw) {
      const len = surname.length;
      if (len === 0) {
        return 'Required field';
      }
    }

    return '';
  }

  const validatePhone = (sw = validatePhoneSw) => {
    if (sw) {
      const len = phone.length;
      if (len === 0) {
        return 'Required field';
      }
      
      // if (phone[0] !== '+') {
      //   return 'Phone number must start with the + character';        
      // }

      if (!isValidLocalPhoneNumber(phone)) {
        return 'Invalid phone number';
      }
    }

    return '';
  }

  const validateEmail = (sw = validateEmailSw) => {
    if (sw) {
      const len = email.length;
      if (len === 0) {
        return 'Required field';
      }

      if (!isValidEmailAddress(email)) {
        return 'Invalid email address';
      }
    }

    return '';
  }

  const validateEmail2 = (sw = validateEmail2Sw) => {
    if (sw) {
      const len = email2.length;
      if (len === 0) {
        return 'Required field';
      }
      
      if (email2 !== email) {
        return `Email 2 does not match`;
      }
    }

    return '';
  }

  const handleClose = () => {
    navigate(`/console/users`);
  }

  const handleGotoUser = (userName: string) => {
    navigate(`/console/users/${userName}`);
  }

  const handleNext = () => {
    if (activeStep === 2) {
      setValidateGivenNameSw(true);
      setValidateSurnameSw(true);
      setValidatePhoneSw(true);
      setValidateEmailSw(true);
      setValidateEmail2Sw(true);

      if (validateGivenName(true) !== '') {
        return;
      }
      if (validateSurname(true) !== '') {
        return;
      }
      if (validatePhone(true) !== '') {
        return;
      }
      if (validateEmail(true) !== '') {
        return;
      }
      if (validateEmail2(true) !== '') {
        return;
      }
      
      if (steps.length === 3) {
        setExecuteNewUser(true);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else if (activeStep === 3) {
      setExecuteNewUser(true);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleBack = () => {
    setValidateGivenNameSw(false);
    setValidateSurnameSw(false);
    setValidatePhoneSw(false);
    setValidateEmailSw(false);
    setValidateEmail2Sw(false);

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleUserOriginChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value
    if (newValue !== userOrigin) {
      // RESET

      setUserOrigin(newValue);
      setCardsUserId('');
      setExternalDocIdKey('');
      setNoDocId(false);

      setSurname('');
      setGivenName('');
      setPhone('');
      setEmail('');
      setEmail2('');
      setNote('');

      setVlan('');

      setValidateGivenNameSw(false);
      setValidateSurnameSw(false);
      setValidatePhoneSw(false);
      setValidateEmailSw(false);
      setValidateEmail2Sw(false);
      
      const elFileSelect = (document.getElementById('fileSelect') as HTMLInputElement);
      if (elFileSelect) {
        elFileSelect.value = '';
      }
    }
  };

  const handleCardsUserChange = (event: SelectChangeEvent) => {
    const idUtente = event.target.value;
    const cardsUser = cardsUsers!.find(user => user.idUtente === idUtente)!;
    setCardsUserId(idUtente);
    setSurname(cardsUser.cognome);
    setGivenName(cardsUser.nome);
    setPhone(cardsUser.tel);
    setEmail(cardsUser.email);
    setEmail2(cardsUser.email);
    setNote('');
  };

  const handleFileSelect = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null && event.target?.files?.length > 0) {
      setExternalDocIdFileName(event.target.files[0].name);
      setExecuteUploadFile(true);
    }
  }

  const handleNoDocId = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNoDocId(event.target.checked);
  };

  const handleRemoveDocId = () => {
    setExternalDocIdKey('');
    
    (document.getElementById('fileSelect') as HTMLInputElement).value = '';
  }

  const handleVlanChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = (event.target as HTMLInputElement).value;
    setVlan(newValue);
  }

  const [activeStep, setActiveStep] = useState(0);

  const givenNameError = validateGivenName();
  const surnameError = validateSurname();
  const phoneError = validatePhone();
  const emailError = validateEmail();
  const email2Error = validateEmail2();

  const handleGivenNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGivenName(event.target.value);
    setValidateGivenNameSw(true);
  };

  const handleSurnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSurname(event.target.value);
    setValidateSurnameSw(true);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
    setValidatePhoneSw(true);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setValidateEmailSw(true);
    setValidateEmail2Sw(true);
  };

  const handleEmail2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail2(event.target.value);
    setValidateEmail2Sw(true);
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const [executeLoadCardsUsers, setExecuteLoadCardsUsers] = useState(true);
  const [executeUploadFile, setExecuteUploadFile] = useState(false);
  const [executeNewUser, setExecuteNewUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sessionData = useRecoilValue(activeSessionState);
  const permissionData = useRecoilValue(permissionSelector);

  const steps = permissionData.canManageMultipleVLans ? stepsWithVLan : stepsWithoutVLan;

  let vlanOptions: string[] = [];
  if (permissionData.canManageMultipleVLans) {
    if (permissionData.canManageAllUsers) {
      vlanOptions = Object.keys(vlanNames);
    } else {
      vlanOptions = (multipleVLanAffiliationMap as any)[sessionData.userData.affiliation.toUpperCase()];
    }
  }

  useEffect(() => {
    if (executeLoadCardsUsers) {
      setTimeout(() => {
        fetch(`${apiBaseUrl}/new-cards-users.php`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            [secHeaderKey]: sessionData.sec
          }
        })
          .then(response => {
            if (response.status === 401) {
              navigate('/login');
            } else if (response.status !== 200) {
              throw Error(`${response.status} - ${response.statusText}`);
            } else {
              return response.json();
            }
          })
          .then(
            response => {
              if (response.success) {
                setCardsUsers(response.users);
                // setCardsUsers([]);
              } else {
                if (typeof response.message === 'string') {
                  setPageResult({
                    title: 'Error',
                    message: `An error has occurred: ${response.message}`,
                    onClose: () => handleClose()
                  });
                } else {
                  setPageResult({
                    title: 'Error',
                    message: `An unknown error has occurred.`,
                    onClose: () => handleClose()
                  });
                }
                console.error('Load cards users error', response);
              }
            });
      }, 1);
      setExecuteLoadCardsUsers(false);
    }

    if (executeUploadFile) {
      const input = document.getElementById('fileSelect')! as HTMLInputElement;
      var formData = new FormData()
      formData.append('uploadFile', input.files![0]);

      fetch(`${apiBaseUrl}/upload-docid.php`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          [secHeaderKey]: sessionData.sec
        },        
        body: formData
      })
        .then(response => {
          if (response.status === 401) {
            navigate('/login');
          } else if(response.status!==200) {
            throw Error(`${response.status} - ${response.statusText}`);
          } else {
            return response.json();
          }
        })
        .then(
          response => {
            setIsLoading(false);
            if (response.success) {
              setExternalDocIdKey(response.tempFileName);
            } else {
              if (typeof response.message === 'string') {
                setPageResult({
                  title: 'Error',
                  message: `An error has occurred: ${response.message}`,
                  onClose: () => setPageResult(null)
                });
              } else {
                setPageResult({
                  title: 'Error',
                  message: `An unknown error has occurred.`,
                  onClose: () => setPageResult(null)
                });
              }
              console.error('New user error', response);
            }
          },
          errorData => {
            setIsLoading(false);
            setPageResult({
              title: 'Server Error',
              message: `An internal server error has occurred.`,
              onClose: () => setPageResult(null)
            });
            console.error('New user server error', errorData);
          }
        );
      setExecuteUploadFile(false);
      setIsLoading(true);
    }

    if (executeNewUser) {
      fetch(`${apiBaseUrl}/new-user.php`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          [secHeaderKey]: sessionData.sec
        },        
        body: JSON.stringify({
          userOrigin, cardsUserId, externalDocIdKey, 
          givenName, surname, phone, email, note,
          vlan
        })
      })
        .then(response => {
          if (response.status === 401) {
            navigate('/login');
          } else if(response.status !== 200) {
            throw Error(`${response.status} - ${response.statusText}`);
          } else {
            return response.json();
          }
        })
        .then(
          response => {
            setIsLoading(false);
            if (response.success) {
              setPageResult({
                title: 'Success',
                message: `New user ${response.userName} has been created.\nUsername and password have been sent to email address ${email}.`,
                onClose: () => handleGotoUser(response.userName)
              });
            } else {
              if (typeof response.message === 'string') {
                setPageResult({
                  title: 'Error',
                  message: `An error has occurred: ${response.message}`,
                  onClose: () => setPageResult(null)
                });
              } else {
                setPageResult({
                  title: 'Error',
                  message: `An unknown error has occurred.`,
                  onClose: () => setPageResult(null)
                });
              }
              console.error('New user error', response);
            }
          },
          errorData => {
            setIsLoading(false);
            setPageResult({
              title: 'Server Error',
              message: `An internal server error has occurred.`,
              onClose: () => setPageResult(null)
            });
            console.error('New user server error', errorData);
          }
        );
      setExecuteNewUser(false);
      setIsLoading(true);
    }
  }, [ executeLoadCardsUsers, executeUploadFile, executeNewUser ]);

  // const sendUsernameTo = validateEmailSw && (emailError === '') && validateEmail2Sw && (email2Error === '') ? email : '';
  const sendUsernameTo = (validateEmail(true) === '') && (validateEmail2(true) === '') ? email : '';

  let canGoNextStep = true;
  if (activeStep === 0) {
    if (userOrigin === '') {
      canGoNextStep = false;
    }
  } else if (activeStep === 1) {
    if (userOrigin === UserOrigin.cards) {
      canGoNextStep = cardsUserId !== '';
    } else {
      canGoNextStep = externalDocIdKey !== '';
      if (noDocId) {
        canGoNextStep = true;
      }
    }
  } else if (activeStep === 3) {
    canGoNextStep = vlan !== '';
  }

  return (
    <ConsolePage title='New User'>
      <ConsolePageBody>

      <Box component='form' autoComplete='off'>
          <Grid container>
            <Grid item sm={12} lg={8}>
              <Paper sx={{ p: 2 }} elevation={3} > 
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                      optional?: React.ReactNode;
                    } = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>

                <Box sx={{ p: 2 }}>
                  
                {activeStep === 0 && (<>
                  <Box sx={{mt: 3}}>
                    <FormControl>
                      <RadioGroup value={userOrigin} onChange={handleUserOriginChange}>
                        <FormControlLabel value={UserOrigin.cards} control={<Radio disabled={cardsUsers === null ? true : cardsUsers.length === 0} />} label="Cards User" />
                        { cardsUsers !== null && cardsUsers.length === 0 && (                          
                          <Typography component='div' variant='caption' sx={{ml: 4, color: 'rgba(255, 255, 255, 0.5)'}}>
                            No Cards User Found
                          </Typography>
                        )}
                        <FormControlLabel value={UserOrigin.external} control={<Radio  />} label='External User' sx={{my: 1}} />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </>)}

                {activeStep === 1&& (<>
                  <Box sx={{ mt: 3 }}>
                    {userOrigin === UserOrigin.cards && ( <>
                      <FormControl fullWidth>
                        <InputLabel id='cardsUser-label'>Cards User</InputLabel>
                        <Select
                          labelId='cardsUser-label'
                          id='cardsUser'
                          value={ cardsUserId }
                          label='Cards User'
                          onChange={handleCardsUserChange}>
                          {cardsUsers && cardsUsers.map(cardsUser => (
                            <MenuItem key={cardsUser.idUtente} value={cardsUser.idUtente}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Avatar src={getCardsUserPhoto(cardsUser.idUtente, cardsUser.photoKey, 2)} sx={{ width: 42, height: 42, mr: 2 }} />
                                <ListItemText
                                  primary={cardsUser.cognome + ' ' + cardsUser.nome}
                                  secondary={ cardsUser.idUtente + ' - ' + cardsUser.struttura + ' - ' + adaptUserType(cardsUser.tipoUtente) + ' (CREATED ON ' + getDateFromDT(cardsUser.creazione_data) + ')' } />
                              </div>
                            </MenuItem>  
                          )) }
                        </Select>
                      </FormControl>
                    </>)}    

                    {userOrigin === UserOrigin.external && (<>               
                        <Typography component='div' variant='h5'>
                          Identyty Document
                        </Typography>
                        <Typography component='div' variant='body1'>
                          Please select and upload a document that may be used to prove the person's identity. <br />
                          Supported file formats: <strong>PDF</strong>  &nbsp;&nbsp;|&nbsp;&nbsp;
                          Max file size: <strong>2MB</strong>
                        </Typography>       
                        <Grid container sx={{ mt: 3 }}>
                          <Grid item lg={3} xl={2}>
                            <Button variant="outlined" component="label" color='secondary' sx={{mb: 3}} disabled={externalDocIdKey !== '' || noDocId}>
                              <FileUploadIcon sx={{mr: 2}} />
                                Upload File
                                <input type='file' accept='application/pdf' hidden
                                id='fileSelect' onChange={handleFileSelect} />
                            </Button>

                            {externalDocIdKey !== '' && (
                              <div>
                              <Button variant="outlined" component="label" color='secondary' sx={{mb: 3}} onClick={handleRemoveDocId}>
                                <DeleteIcon sx={{mr: 2}} />
                                  Remove File
                              </Button>       
                              </div>                     
                            )}

                          </Grid>
                          <Grid item lg={9} xl={10} sx={{ display: { xs: 'none', lg: 'block' } }}>
                          {externalDocIdKey !== '' && (<>
                            <Box sx={{ml: 3}}>
                              <iframe
                                title='DocId'
                                src={`${apiBaseUrl}/load-temp-external-docid.php?key=${externalDocIdKey}#page=0`}
                                frameBorder='0' scrolling='auto' height='400' width='100%'
                                ></iframe>
                            </Box>
                          </>)}
                          </Grid>
                        </Grid>      

                        {externalDocIdKey !== '' && (
                          <Typography component='div' variant='body1' sx={{ display: { xs: 'block', lg: 'none' } }}>
                            File Name: <strong>{ externalDocIdFileName }</strong>
                          </Typography>
                        )}

                        {externalDocIdKey === '' && permissionData.canCreateExternalNoDocUser && (
                          <FormControlLabel control={<Checkbox checked={noDocId} onChange={handleNoDocId} disabled={externalDocIdKey !== ''} />} label="Create External User Without DocID" />            
                        )}
                    </>)}                      

                  </Box>
                </>)}

                {activeStep === 2 && (                  
                <Box sx={{mt: 3}}>
                  <TextField id='txtGivenName' label='Given Name *' variant='filled'
                    fullWidth autoFocus={!smallDevice}
                    value={givenName}
                    onChange={handleGivenNameChange}
                    helperText={givenNameError}
                    error={givenNameError !== ''}
                    inputProps={{ maxLength: 64 }}
                  />
  
                  <TextField id='txtSurname' label='Surname *' variant='filled'
                    fullWidth sx={{ mt: 2 }}
                    value={surname}
                    onChange={handleSurnameChange}
                    helperText={surnameError}
                    error={surnameError !== ''}
                    inputProps={{ maxLength: 64 }}
                  />   
                  
                  <TextField id='txtPhone' label='Phone *' variant='filled'
                    fullWidth sx={{ mt: 5 }}
                    value={phone}
                    onChange={handlePhoneChange}
                    helperText={phoneError}
                    error={phoneError !== ''}
                    inputProps={{ maxLength: 15 }}
                  /> 
  
                  <TextField id='txtEmail' label='Email *' variant='filled'
                    fullWidth sx={{ mt: 5 }}
                    value={email}
                    onChange={handleEmailChange}
                    helperText={emailError}
                    error={emailError !== ''}
                    inputProps={{ maxLength: 128 }}
                  /> 
                  <TextField id='txtEmail2' label='Email (retype) *' variant='filled'
                    fullWidth sx={{ mt: 2 }}
                    value={email2}
                    onChange={handleEmail2Change}
                    helperText={email2Error}
                    error={email2Error !== ''}
                    inputProps={{ maxLength: 128 }}
                  /> 
  
                  <TextField id='txtNote' label='Notes' variant='filled'
                    fullWidth sx={{ mt: 5 }}
                    value={note}
                    onChange={handleNoteChange}
                    inputProps={{ maxLength: 164 }}
                    multiline minRows={2}
                  /> 
                </Box>
                )}

                {activeStep === 3 && (<>
                  <Box sx={{ mt: 3 }}>
                    <Typography component='div' variant='h5'>
                      VLAN
                    </Typography>
                    <Typography component='div' variant='body1' sx={{ mb: 3 }}>
                      Please select the VLAN to associate the new user with:
                    </Typography> 
                    <FormControl>
                      <RadioGroup value={vlan} onChange={handleVlanChange}>
                          {vlanOptions.map((vlan, index) => (
                            <FormControlLabel value={vlan} control={<Radio />} label={getVlanDescName(vlan)}
                              sx={{ my: (index === 0 ? 0 : 1) }} />
                          )) }
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </>)}

                </Box>
              </Paper>

              <Box sx={{ p: 2, mt: 3, display: 'flex' }}>              
                <Button aria-label='save' variant='contained' sx={{ mr: 3 }}
                  onClick={handleBack} disabled={activeStep === 0}>
                  <NavigateBeforeIcon sx={{mr: 2}} /> Back
                </Button>
                
                {activeStep !== steps.length - 1 && (                
                  <Button aria-label='save' variant='contained'
                    onClick={handleNext} disabled={!canGoNextStep}>
                    <NavigateNextIcon sx={{mr: 2}} /> Next
                    </Button>
                )}                  

                {activeStep === steps.length - 1 && (
                  <Button aria-label='save' variant='contained'
                    onClick={handleNext} disabled={!canGoNextStep}>
                    <CheckIcon sx={{mr: 2}} /> Save
                  </Button>                
                )}

                <Divider orientation='vertical' sx={{ mx: 5 }} flexItem  />
                
                <Button aria-label='cancel' variant='outlined' onClick={handleClose}>
                  <ClearIcon sx={{mr: 2}} /> Cancel
                </Button>
              </Box>

            </Grid>
          </Grid>
      </Box>



        <Modal
          open={pageResult !== null}
          onClose={pageResult?.onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box sx={modalBoxStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              { pageResult?.title }
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            { pageResult?.message.split('\n').map(line => <p>{ line }</p>) }       
            </Typography>
            <Button aria-label='continue' variant='contained' onClick={pageResult?.onClose} sx={{mt: 3}}>
              <PlayArrowIcon sx={{ mr: 2 }} /> Continue
            </Button>
          </Box>
        </Modal>    
        {isLoading && <WaitLoading />}
      </ConsolePageBody>
    </ConsolePage>
  );
}