export function getInitials(str: string) {
  const names = str.split(' ');
  let result = names[0].substring(0, 1).toUpperCase();
  
  if (names.length > 1) {
      result += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return result;
}

const mailFormat=/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
export function isValidEmailAddress(str: string) {
  if (str.match(mailFormat)) {
    return true;
  } else {
    return false;
  }
}

// const phoneFormat = /^\+(?:[0-9] ?){6,14}[0-9]$/;
const phoneFormat = /^\+(?:[0-9]){7,15}$/;
export function isValidPhoneNumber(str: string) {
  if (str.match(phoneFormat)) {
    return true;
  } else {
    return false;
  }
}

const localPhoneFormat =/^\+?(?:[0-9]){7,15}$/;
export function isValidLocalPhoneNumber(str: string) {
  if (str.match(localPhoneFormat)) {
    return true;
  } else {
    return false;
  }
}

const macAddressFormat = /^[A-F0-9]{2}(-[A-F0-9]{2}){5}$/;
export function isValidMacAddress(str: string) {
  if (str.match(macAddressFormat)) {
    return true;
  } else {
    return false;
  }
}

// BUGFIX Safari: Invalid regular expression: invalid group specifier name 
// const fqdnFormat = /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{1,63}(?<!-)\.)+[a-zA-Z]{2,63}$)/;
// Safari doesn't support lookbehind yet (?<)
// Use an alternative version
const fqdnFormat = /(?=^.{1,254}$)(^(?:(?!\d+\.)[a-zA-Z0-9_\-]{1,63}\.?)+(?:[a-zA-Z]{2,})$)/;

export function isValidFqdn(str: string) {
  if (str.match(fqdnFormat)) {
    return true;
  } else {
    return false;
  }
}
