import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import ConsolePage from '../../components/ConsolePage';
import ConsolePageBody from '../../components/ConsolePageBody';
import { GridBool } from '../../components/GridBool';
import WaitLoading from '../../components/WaitLoading';
import { apiBaseUrl, modalBoxStyle, secHeaderKey } from '../../consts';
import { DeviceData } from '../../data';
import { activeSessionState } from '../../state';

function WaitSkeleton() {
  return (
    <>
      <Skeleton animation="wave" variant='text' />
      <Skeleton animation="wave" variant='text' />
      <Skeleton animation="wave" variant='text' />
    </>
  );
}

interface WolButtonProps {
  id: string;
  hasFocus: boolean;
  disabled: boolean;
  onClick: (id: string) => void;
}

const WolButton = (props: WolButtonProps) => {
  const performWOL = () => {
    props.onClick(props.id);
  }

  const style = props.disabled ? { opacity: 0.3 } : {};

  return (
    <Button aria-label='Wake On Lan'
      variant='outlined'
      size='small'
      color='secondary'
      disabled={props.disabled}
      sx={style}
      tabIndex={props.hasFocus ? 0 : -1} onClick={performWOL} >
      <PowerSettingsNewIcon sx={{mr: 1}} /> Wake On LAN
    </Button>
  );
}


export default function MyDevices() {
  const navigate = useNavigate();

  const sessionData = useRecoilValue(activeSessionState);
  const [devices, setDevices] = useState<DeviceData[] | null>(null);

  const [executeLoadDevices, setExecuteLoadDevices] = useState(true);
  const [executeWol, setExecuteWol] = useState(false);
  
  const [wolMac, setWolMac] = useState('');
  const [wolConfirmationOpen, setWolConfirmationOpen] = useState(false);
  const [wolConfirmationMessage, setWolConfirmationMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [pageResult, setPageResult] = useState<{
    title: string;
    message: string;
    onClose: () => void
  } | null>(null);

  const handlePerformWOL = (id: string) => {
    const device = devices!.find(device => device.id === id);
    const mac = device!.macaddr;
    const desc = device!.note;
    setWolConfirmationMessage(`Are you sure you want to wake device  ${mac}  (${desc}) ?`);
    setWolConfirmationOpen(true);
    setWolMac(mac);
  }

  const handleWolConfirmationDialogClose = (confirmed: boolean) => {
    setWolConfirmationOpen(false);
    if (confirmed) {
      setExecuteWol(true);
    }
  }

  const cols: GridColDef[] = [
    { field: 'wol', headerName: '', width: 165, renderCell: (params: GridRenderCellParams<string>) => (
      <WolButton id={params.id.toString()} hasFocus={params.hasFocus} onClick={handlePerformWOL} disabled={params.row.wol !== '1'} />
    )},   
    { field: 'macaddr', headerName: 'MAC Address', width: 200, renderCell: (params: GridRenderCellParams<number>) => (
      <pre style={{ paddingTop: 0, paddingBottom: 0, display: 'inline' }}>{params.value}</pre>
    )},
    { field: 'fqdn', headerName: 'FQDN', width: 300 },
    { field: 'ismobile', headerName: 'Mobile', align:'center', headerAlign: 'center', width: 100, renderCell: (params: GridRenderCellParams<number>) => (
      <GridBool value={params.value}></GridBool>
    )},
    { field: 'ispersonal', headerName: 'Personal', align:'center', headerAlign: 'center', width: 100, renderCell: (params: GridRenderCellParams<number>) => (
      <GridBool value={params.value}></GridBool>
    )},    
    { field: 'note', headerName: 'Description', width: 200, flex: 1, },
    { field: 'createdOn', headerName: 'Creation Time', width: 200 },
  ];

  useEffect(() => {
    if (executeLoadDevices) {
      setTimeout(() => {
        fetch(`${apiBaseUrl}/my-devices.php`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            [secHeaderKey]: sessionData.sec
          }
        })
          .then(response => {
            if (response.status === 401) {
              navigate('/login');
            } else if (response.status !== 200) {
              throw Error(`${response.status} - ${response.statusText}`);
            } else {
              return response.json();
            }
          })
          .then(
            response => {
              if (response.success) {
                setDevices(response.devices);
              } else {
                console.error('Error loading my-devices.');
              }
            });
      }, 100);
      setExecuteLoadDevices(false);
    }

    if (executeWol) {
      setTimeout(() => {
        fetch(`${apiBaseUrl}/my-wol.php`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            [secHeaderKey]: sessionData.sec
          },
          body: JSON.stringify({ macaddr: wolMac })
        })
          .then(response => {
            setIsLoading(false);
            if (response.status === 401) {
              navigate('/login');
            } else if (response.status !== 200) {
              throw Error(`${response.status} - ${response.statusText}`);
            } else {
              return response.json();
            }
          }, reject => {
            setIsLoading(false);
            setPageResult({
              title: 'Error',
              message: `An error has occurred: ${reject}`,
              onClose: () => setPageResult(null)
            });
          })
          .then(
            response => {
              if (response.success) {
                setPageResult({
                  title: 'Success',
                  message: `Wake On Lan request successfully sent.`,
                  onClose: () => setPageResult(null)
                });
              } else {
                if (typeof response.message === 'string') {
                  setPageResult({
                    title: 'Error',
                    message: `An error has occurred: ${response.message}`,
                    onClose: () => setPageResult(null)
                  });
                } else {
                  setPageResult({
                    title: 'Error',
                    message: `An unknown error has occurred.`,
                    onClose: () => setPageResult(null)
                  });
                }
                console.error('Resend my-guest password error', response);
              }
            });
      }, 1000);
      setExecuteWol(false);
      setIsLoading(true);
    }
  }, [ executeLoadDevices, executeWol ]);      

  return (
    <ConsolePage title='My Devices'>
      <ConsolePageBody>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%'}}>
          <Paper sx={{ p: 2, flexGrow: 1 }} elevation={3} > 
          {devices === null && <WaitSkeleton />}
          {devices !== null && <DataGrid rows={devices} columns={cols} initialState={{
              sorting: {
                sortModel: [{ field: 'createdOn', sort: 'desc' }],
              },
             }} />}
          </Paper>
        </Box>

        <Modal
          open={pageResult !== null}
          onClose={pageResult?.onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box sx={modalBoxStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              { pageResult?.title }
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            { pageResult?.message }
            </Typography>
            <Button aria-label='continue' variant='contained' onClick={pageResult?.onClose} sx={{mt: 3}}>
              <PlayArrowIcon sx={{ mr: 2 }} /> Continue
            </Button>
          </Box>
        </Modal> 

        {isLoading && <WaitLoading />}

        <ConfirmationDialog
          open={wolConfirmationOpen} title='Wake On Lan' message={wolConfirmationMessage}
          onClose={handleWolConfirmationDialogClose} />
      </ConsolePageBody>
    </ConsolePage>
  );
}