import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Avatar from '@mui/material/Avatar';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';

import LoginIcon from '@mui/icons-material/Login';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Copyright from '../components/Copyright';
import WaitLoading from '../components/WaitLoading';
import { apiBaseUrl, usernameSuffix } from '../consts';
import { SessionData, sessionState } from '../state';
import loginBgImage from './login-bg-001.jpg';

const usernameSuffixLen = usernameSuffix.length;

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation() as any;

  const smallDevice = !useMediaQuery('(min-width:600px)');

  const from = location.state?.from?.pathname || "/console";

  const [username, setUsername] = React.useState(localStorage.getItem('username') ?? '');
  const [password, setPassword] = React.useState('');
  const [remember, setRemember] = React.useState(localStorage.getItem('remember') === 'true' ? true : false);
  const passwordInputRef = React.useRef() as any;

  const [validateUsernameSw, setValidateUsernameSw] = React.useState(false);
  const [validatePasswordSw, setValidatePasswordSw] = React.useState(false);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
    setValidateUsernameSw(true);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setValidatePasswordSw(true);
  };

  const validateUsername = (sw = validateUsernameSw) => {
    let result = '';
    if (sw) {
      if (username.trim().length === 0) {
        result = 'Required field';
      }
      if (result === '') {
        if (username.length <= usernameSuffixLen || username.toLowerCase().slice(0 - usernameSuffixLen) !== usernameSuffix) {
          result = `Username must end with ${usernameSuffix}`;
        }    
      }
    }
    return result;
  }

  const validatePassword = (sw = validatePasswordSw) => {
    let result = '';
    if (sw) {
      if (password.trim().length === 0) {
        result = 'Required field';
      }  
    }
    return result;
  }

  const usernameError = validateUsername();
  const passwordError = validatePassword();

  const handleRememberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('remember', event.target.checked ? 'true' : 'false')
    if (!event.target.checked) {
      localStorage.removeItem('username');
    }
    setRemember(event.target.checked);
  };

  const [sessionData, setSessionData] = useRecoilState(sessionState);

  const [executeLogin, setExecuteLogin] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [loginResultOpen, setLoginResultOpen] = React.useState(false);

  const handleLoginResultClose = () => {
    setLoginResultOpen(false);
  };

  const handleLoginSuccess = (sessionData: SessionData) => {
    if (remember) {
      localStorage.setItem('username', username);
    }

    setSessionData(sessionData);
    
    // Send them back to the page they tried to visit when they were
    // redirected to the login page. Use { replace: true } so we don't create
    // another entry in the history stack for the login page.  This means that
    // when they get to the protected page and click the back button, they
    // won't end up back on the login page, which is also really nice for the
    // user experience.
    navigate(from, { replace: true });
  }
  
  React.useEffect(() => {
    if (executeLogin) {
      fetch(`${apiBaseUrl}/login.php`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          username,
          password
        })
      })
        .then(response => response.json())
        .then(
          response => {
            setIsLoading(false);
            if (response.success) {
              handleLoginSuccess(response.sessionData);
            } else {
              passwordInputRef.current.focus();
              setLoginResultOpen(true);
            }
          },
          errorData => {
            passwordInputRef.current.focus();
            setIsLoading(false);
            setLoginResultOpen(true);
          }
        );
      setExecuteLogin(false);
      setIsLoading(true);
      setValidatePasswordSw(false);
      setPassword('');
    }
  }, [ executeLogin ]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setValidateUsernameSw(true);
    setValidatePasswordSw(true);

    if (validateUsername(true)) {
      return;
    }

    if (validatePassword(true)) {
      return;
    }

    setExecuteLogin(true);
  };

  React.useEffect(() => {
    if (sessionData) {
      navigate('/console');
    }
  }, []);

  return (
    <>
      <img
        src={loginBgImage}
        alt='background'
        style={{
          objectFit: 'cover',
          position: 'fixed',
          width: '100vw',
          height: '100vh'
        }}
      />
      { /* <ParticlesBox></ParticlesBox> */}
      <Grid container component='main' sx={{ position: 'fixed', height: '100vh' }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={8}>
        </Grid>

        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square sx={{
          backgroundColor: 'rgba(0,0,0, .8)',
          // backdropFilter: 'blur(2px)',
        }}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography component='h1' variant='h5'>
              Network Access Management
            </Typography>

            <Typography component='h2' variant='subtitle1'>
              CNR - Area territoriale di Ricerca di Padova
            </Typography>          

            <Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 8 }}>
              <TextField
                margin='normal'
                required
                fullWidth
                id='username'
                label='Username'
                name='username'
                variant='filled'
                autoComplete='username'
                autoFocus={!smallDevice && username === ''}
                value={username}
                onChange={handleUsernameChange}
                helperText={usernameError}
                placeholder={usernameSuffix}
                error={usernameError !== ''}
              />
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                variant='filled'
                autoFocus={!smallDevice && username !== ''}
                inputRef={passwordInputRef}
                value={password}
                onChange={handlePasswordChange}
                helperText={passwordError}
                error={passwordError !== ''}
              />
              <FormControlLabel
                control={<Checkbox value='remember' color='primary' checked={remember} onChange={handleRememberChange} />}
                label='Remember username'
              />

              {loginResultOpen && <Alert onClose={handleLoginResultClose} severity="error" sx={{ py: 1, mt: 1 }}>
                <AlertTitle>Error</AlertTitle>
                Invalid username or password.
              </Alert>}

              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 4, mb: 2 }}
              >
                <LoginIcon sx={{ mr: 2 }} /> Sign In
              </Button>

              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      {isLoading && <WaitLoading />}
      </>
  );
}