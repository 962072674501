import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export default function Copyright(props: any) {
  return (
    <Typography component='p' variant='body2' color='text.secondary' align='center' {...props}>
      {'Copyright © '}
      <Link color='inherit' href='https://www.pd.cnr.it/'>
        CNR - Area territoriale di Ricerca di Padova
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}