import { AdrPos, UserLevel, vlanNames } from '../consts';
import { UserData } from '../data';

export function formatInfo(str: string) {
  return str ? str : '---';
};

export function adaptAffiliation(affiliation: string) {
  return affiliation.toUpperCase();
}

export function adaptUserType(userType: string) {
  switch (userType) {
    case AdrPos.CNR:
      return 'CARDS / CNR';
    case AdrPos.Esterni:
      return 'CARDS / EXTERNAL';
    case AdrPos.Provvisorio:
      return 'CARDS / TEMPORARY';
    case AdrPos.esterno:
      return 'EXTERNAL';
    case AdrPos.esternoNodoc:
      return 'EXTERNAL (NO-DOC)';  
    case AdrPos.admin:
      return 'ADMIN';
  }

  return userType.toUpperCase();
}

export function isCardsUser(userType: string, cardsUserId: string) {
  if (cardsUserId) {
    return true;
  }

  switch (userType) {
    case 'CNR':
      return true;
    case 'Esterni':
      return true;
    case 'Provvisorio':
      return true;
  }

  return false;
}

export function getUserLevel(user: UserData): UserLevel {
  if (user.isAdmin) {
    return UserLevel.administrator;
  }
  if (user.isSuperManager) {
    return UserLevel.superManager;
  }
  if (user.isManager) {
    return UserLevel.manager;
  }

  return UserLevel.user;
}

export function formatUserLevel(level: UserLevel) {
  switch (level) {
    case UserLevel.administrator:
      return 'ADMINISTRATOR';
    case UserLevel.superManager:
      return 'SUPER MANAGER';
    case UserLevel.manager:
      return 'MANAGER';
    default:
      return 'USER';
  }
}

export function hasRoles(user: UserData) {
  const level = getUserLevel(user);
  
  if (level >= UserLevel.manager) {
    return true;
  }

  if (user.canMakeGuest) {
    return true;
  }

  return false;
}

export function getVlanDescName(vlanId: string) {
  if (vlanId in vlanNames) {
    return `${vlanId} (${(vlanNames as any)[vlanId]})`;
  }
  return vlanId;
}

export function getCardsUserPhoto(userId: string, photoKey: string, size: 0 | 1 | 2) {
  if (photoKey !== '') {
    return `https://cards.pd.cnr.it/repository/utenti/photo/${userId}/f_${photoKey}__p${size}.jpg`;
  } else {
    return `https://cards.pd.cnr.it/gfx/userSilhouette__p${size}.png`;
  }
}

export function getDateFromDT(dt: string) {
  return dt.substring(0, 10);
}

export function getFileName(filePath: string) {
  return filePath.replace(/^.*[\\\/]/, '')
}