import Box from '@mui/material/Box';
import * as React from 'react';
import { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { apiBaseUrl } from '../consts';
import { sessionState } from '../state';
import WaitLoading from './WaitLoading';

export default function SessionResumer({ children }: { children: JSX.Element }) {
  const [fetchStage, setFetchStage] = useState('unloaded');
  const setSessionData = useSetRecoilState(sessionState);

  React.useEffect(() => {    
    if (fetchStage === 'unloaded') {
      fetch(`${apiBaseUrl}/resume-session.php`, {
        method: 'POST',
        credentials: 'include'
      })
        .then(response => response.json())
        .then(
          response => {
            if (response.success) {
              setSessionData(response.sessionData);
            }
            setFetchStage('loaded');
          }
        );
      setFetchStage('loading');
    }
  }, [ fetchStage ]);

  if (fetchStage === 'loaded') {
    return children;
  } else {
    return (<Box sx={{
      backgroundColor: (theme) =>
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[900],
      position: 'fixed',
      width: '100vw',
      height: '100vh'
    }}>
      <WaitLoading />
    </Box>);
  }
}
