import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { RecoilRoot } from 'recoil';
import SessionResumer from './components/SessionResumer';
import Router from './Router';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    // primary: lime,
    // secondary: amber,
  },
});

function App() {
  return (
    <>
      <CssBaseline enableColorScheme />
      <RecoilRoot>
        <ThemeProvider theme={darkTheme}>
          <SessionResumer>
            <Router />
          </SessionResumer>
        </ThemeProvider>
      </RecoilRoot>
    </>
  );
}

export default App;
