import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ConsolePage from '../../components/ConsolePage';
import ConsolePageBody from '../../components/ConsolePageBody';
import WaitLoading from '../../components/WaitLoading';
import { apiBaseUrl, modalBoxStyle, secHeaderKey } from '../../consts';
import { UserData } from '../../data';
import { activeSessionState, permissionSelector } from '../../state';
import { adaptAffiliation } from '../../utils/adapters';
import { isValidFqdn, isValidMacAddress } from '../../utils/string';

const validMACAddressChars = [
  '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
  'A', 'B', 'C', 'D', 'E', 'F'
];

function WaitSkeleton() {
  return (
    <>
      <Skeleton animation="wave" variant='text' />
      <Skeleton animation="wave" variant='text' />
      <Skeleton animation="wave" variant='text' />
    </>
  );
}

export default function NewUserDevice() {
  const navigate = useNavigate();
  const { userName } = useParams<{
    userName: string
  }>();

  const smallDevice = !useMediaQuery('(min-width:600px)');

  const [macAddress, setMacAddress] = useState('');
  const [fqdn, setFqdn] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const [isPersonal, setIsPersonal] = useState(false);
  const [note, setNote] = useState('');

  const [pageResult, setPageResult] = useState<{
    title: string;
    message: string;
    onClose: () => void
  } | null>(null);

  const [validateMacAddressSw, setValidateMacAddressSw] = useState(false);
  const [validateFqdnSw, setValidateFqdnSw] = useState(false);

  const validateMacAddress = (sw = validateMacAddressSw) => {
    if (sw) {
      const len = macAddress.length;
      if (len === 0) {
        return 'Required field';
      }

      if (!isValidMacAddress(macAddress)) {
        return 'Invalid MAC Address';
      }
    }

    return '';
  }

  const validateFqdn = (sw = validateFqdnSw) => {
    if (sw) {
      if (fqdn.length > 0) {
        if (!isValidFqdn(fqdn)) {
          return 'Invalid Fully Qualified Domain Name';
        }
      }
    }

    return '';
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setValidateMacAddressSw(true);
    setValidateFqdnSw(true);

    if (validateMacAddress(true)) {
      return;
    }
    if (validateFqdn(true)) {
      return;
    }

    setExecuteNewUserDevice(true);
  };  

  const handleClose = () => {
    navigate(`/console/users/${userName}`);
  }

  const macAddressError = validateMacAddress();
  const fqdnError = validateFqdn();

  const formatMAC = (mac: string) => {
    const flatMac = mac.toUpperCase().split('').filter(char => validMACAddressChars.indexOf(char) !== -1).join('');
    let result = '';
    for (let i = 0; i < flatMac.length; i += 2) {
      if (i >= 2) {
        result += '-';
      }
      const sub = flatMac.substring(i, i + 2);
      result += sub;
      /*
      if (i < 10) {
        if (sub.length === 2) {
          result += '-';
        }
      }
      */
    }
    return result;
  }

  const handleMacAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMacAddress(formatMAC(event.target.value));
    setValidateMacAddressSw(true);
  };

  const handleFqdnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFqdn(event.target.value.trim().toLowerCase());
    setValidateFqdnSw(true);
  };

  const handleIsMobileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsMobile(event.target.checked);
  };

  const handleIsPersonalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsPersonal(event.target.checked);
  };

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const [executeLoadUser, setExecuteLoadUser] = useState(true);
  const [executeNewUserDevice, setExecuteNewUserDevice] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const sessionData = useRecoilValue(activeSessionState);
  const permissionData = useRecoilValue(permissionSelector);
  const [user, setUser] = useState<UserData | null>(null);

  useEffect(() => {
    if (executeLoadUser) {
      setTimeout(() => {
        fetch(`${apiBaseUrl}/user-details.php`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            [secHeaderKey]: sessionData.sec
          },
          body: JSON.stringify({ userName })
        })
          .then(response => {
            if (response.status === 401) {
              navigate('/login');
            } else if (response.status !== 200) {
              throw Error(`${response.status} - ${response.statusText}`);
            } else {
              return response.json();
            }
          })
          .then(
            response => {
              if (response.success) {
                setUser(response.userDetails.user);
              } else {
                if (typeof response.message === 'string') {
                  setPageResult({
                    title: 'Error',
                    message: `An error has occurred: ${response.message}`,
                    onClose: () => handleClose()
                  });
                } else {
                  setPageResult({
                    title: 'Error',
                    message: `An unknown error has occurred.`,
                    onClose: () => handleClose()
                  });
                }
                console.error('Load user error', response);
              }
            });
      }, 100);
      setExecuteLoadUser(false);
    }

    if (executeNewUserDevice) {
      fetch(`${apiBaseUrl}/new-user-device.php`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          [secHeaderKey]: sessionData.sec
        },        
        body: JSON.stringify({
          userName,
          macAddress,
          fqdn,
          isMobile: isMobile ? '1' : '0',
          isPersonal: isPersonal ? '1' : '0',
          note
        })
      })
        .then(response => {
          if (response.status === 401) {
            navigate('/login');
          } else if(response.status!==200) {
            throw Error(`${response.status} - ${response.statusText}`);
          } else {
            return response.json();
          }
        })
        .then(
          response => {
            setIsLoading(false);
            if (response.success) {
              setPageResult({
                title: 'Success',
                message: 'New device has been added.\nWARNING: IT MAY TAKE UP TO 5 MINUTES FOR THE CHANGE TO TAKE EFFECT.',
                onClose: () => handleClose()
              });
            } else {
              if (typeof response.message === 'string') {
                setPageResult({
                  title: 'Error',
                  message: `An error has occurred: ${response.message}`,
                  onClose: () => setPageResult(null)
                });
              } else {
                setPageResult({
                  title: 'Error',
                  message: `An unknown error has occurred.`,
                  onClose: () => setPageResult(null)
                });
              }
              console.error('New user device error', response);
            }
          },
          errorData => {
            setIsLoading(false);
            setPageResult({
              title: 'Server Error',
              message: `An internal server error has occurred.`,
              onClose: () => setPageResult(null)
            });
            console.error('New user-device server error', errorData);
          }
        );
      setExecuteNewUserDevice(false);
      setIsLoading(true);
    }
  }, [executeNewUserDevice]);
  

  return (
    <ConsolePage title='New Device'>
      <ConsolePageBody>
        <Box component='form' autoComplete='off' noValidate onSubmit={handleSubmit}>
          <Grid container>
            {user === null && <Grid item lg={8}><WaitSkeleton /></Grid> }
            {user !== null && (
              <Grid item lg={8}>
                <Paper sx={{ p: 2 }} elevation={3} >
                  <Grid container>
                    <Grid item xs={12} lg={7}>
                      <Typography component='p' variant='subtitle1'>
                        User:
                      </Typography>
                      <Typography component='p' variant='h5'>
                        {user.displayName}
                      </Typography>
                      <Typography component='p' variant='subtitle2'>
                        {userName}
                      </Typography>
                    
                    </Grid>
                    <Grid item xs={12} lg={5}>
                      <Typography component='p' variant='subtitle1'>
                        Affiliation:
                      </Typography>
                      <Typography component='p' variant='h5'>
                        {adaptAffiliation(user.affiliation)}
                      </Typography>
                    </Grid>
                  </Grid>

                  <TextField id='txtMacAddress' label='MAC Address *' variant='filled'
                    fullWidth sx={{ mt: 4 }} autoFocus
                    value={macAddress}
                    placeholder='00-00-00-00-00-00'
                    onChange={handleMacAddressChange}
                    helperText={macAddressError}
                    error={macAddressError !== ''}
                    inputProps={{ maxLength: 17, style: { fontFamily: 'monospace', fontSize: 24, } }}
                  />

                  <TextField id='txtSurname' label='FQDN' variant='filled'
                    fullWidth sx={{ mt: 2 }}
                    value={fqdn}
                    onChange={handleFqdnChange}
                    helperText={fqdnError}
                    error={fqdnError !== ''}
                    inputProps={{ maxLength: 156 }}
                  />

                  <FormControlLabel control={<Switch checked={isMobile} onChange={handleIsMobileChange} />} label="Mobile Device" sx={{ mt: 3, mr: 8 }} />
                  <FormControlLabel control={<Switch checked={isPersonal} onChange={handleIsPersonalChange} />} label="Personal Device" sx={{ mt: 3 }} />
                
                  <TextField id='txtNote' label='Description' variant='filled'
                    fullWidth sx={{ mt: 5 }}
                    value={note}
                    onChange={handleNoteChange}
                    inputProps={{ maxLength: 128 }}
                    multiline minRows={2}
                  />
                </Paper>

                <Box sx={{ p: 2, mt: 1 }}>
                  <Button aria-label='save' variant='contained' sx={{ mr: 3, mt: 2 }} type='submit' fullWidth={smallDevice}>
                    <CheckIcon sx={{ mr: 2 }} /> Save
                  </Button>
                  <Button aria-label='cancel' variant='outlined' sx={{ mt: 2 }} onClick={handleClose} fullWidth={smallDevice}>
                    <ClearIcon sx={{ mr: 2 }} /> Cancel
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <Modal
          open={pageResult !== null}
          onClose={pageResult?.onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box sx={modalBoxStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              { pageResult?.title }
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            { pageResult?.message.split('\n').map(line => <p>{ line }</p>) }       
            </Typography>
            <Button aria-label='continue' variant='contained' onClick={pageResult?.onClose} sx={{mt: 3}}>
              <PlayArrowIcon sx={{ mr: 2 }} /> Continue
            </Button>
          </Box>
        </Modal>  
        {isLoading && <WaitLoading />}

        </ConsolePageBody>
    </ConsolePage>
  );
}