import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';

interface ConfirmationDialogProps {
  title: string;
  message: string;
  open: boolean;
  okText?: string;
  cancelText?: string | null;
  onClose: (confirmed: boolean) => void;
}

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  const { title, message, open, onClose } = props;
  let { okText, cancelText } = props;
  if (okText === undefined) {
    okText = 'Ok';
  }
  if (cancelText === undefined) {
    cancelText = 'Cancel';
  }

  const smallDevice = !useMediaQuery('(min-width:1024px)');

  const handleOk = () => {
    onClose(true);
  }

  const handleCancel = () => {
    onClose(false);
  }

  return (
    <Dialog maxWidth='md' open={open}
      sx={{ '& .MuiDialog-paper': { width: smallDevice ? '100%' : '30%' } }}
      >
      <DialogTitle> { title } </DialogTitle>
      <DialogContent>
        { message }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOk}>
          <CheckIcon sx={{mr: 2}} /> { okText }
        </Button>
        {cancelText !== null && <Button onClick={handleCancel} sx={{ml: 3}} >
          <ClearIcon sx={{mr: 2}} /> { cancelText }
        </Button>}
      </DialogActions>
    </Dialog>
  );
}