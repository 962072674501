import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import notFoundImage from './not-found-404..jpg';

export default function NotFound() {
  const navigate = useNavigate();

  const handleContinueClick = () => {
    navigate('/');
  };

  return (
    <Box sx={{
      backgroundColor: '#0C021C',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <img src={notFoundImage} alt='background' style={{ height: '50%' }} /> <br />
      <Button variant='contained' sx={{ mb: 10 }} onClick={handleContinueClick}>
        Continue
      </Button>
    </Box>
  );
}
