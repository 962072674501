import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { passwordStrength } from 'check-password-strength';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ConsolePage from '../../components/ConsolePage';
import ConsolePageBody from '../../components/ConsolePageBody';
import WaitLoading from '../../components/WaitLoading';
import { apiBaseUrl, modalBoxStyle, secHeaderKey } from '../../consts';
import { activeSessionState } from '../../state';

const defaultMinLen = 9;
const maxLen = 40;

const charNums = '0123456789';
const charLower = 'abcdefghijklmnopqrstuvwxyz';
const charUpper = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const charSpecial = '!$+-*/=_.()@#';

export default function ChangePassword() {
  const navigate = useNavigate();

  const smallDevice = !useMediaQuery('(min-width:600px)');

  const sessionData = useRecoilValue(activeSessionState);
  let minLen = defaultMinLen;
  if (!sessionData.enablePasswordPolicy) {
    minLen = 1;
  }

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [pageResult, setPageResult] = useState<{
    title: string;
    message: string;
  } | null>(null);

  const [displayPassword, setDisplayPassword] = useState(false);

  const [validatePasswordSw, setValidatePasswordSw] = useState(false);
  const [validatePassword2Sw, setValidatePassword2Sw] = useState(false);

  const validatePassword = (sw = validatePasswordSw) => {
    if (sw) {
      const len = password.length;
      if (len === 0) {
        return 'Required field';
      }

      if (!sessionData.enablePasswordPolicy) {
        return '';
      }

      const firstLetter = password[0];
      if (!charLower.includes(firstLetter) && !charUpper.includes(firstLetter)) {
        return `First character must be a letter`;
      }

      if (len < minLen) {
        return `Min lenght is ${minLen} characters`;
      }

      const passwordChars = password.split('');
      const atLeastNumber = passwordChars.some(char => charNums.includes(char));
      const atLeastCharLower = passwordChars.some(char => charLower.includes(char));
      const atLeastCharUpper = passwordChars.some(char => charUpper.includes(char));
      const atLeastCharSpecial = passwordChars.some(char => charSpecial.includes(char));
      if (!atLeastNumber || !atLeastCharLower || !atLeastCharUpper || !atLeastCharSpecial) {
        return `At least one capital letter, one lowercase, one digit and one special character`;
      }

      const someInvalidChar = passwordChars.some(char => {
        if (charNums.includes(char)) {
          return false;
        }
        if (charLower.includes(char)) {
          return false;
        }
        if (charUpper.includes(char)) {
          return false;
        }
        if (charSpecial.includes(char)) {
          return false;
        }

        return true;
      });

      if (someInvalidChar) {
        return `Invalid special character`;
      }
    }

    return '';
  }

  const validatePassword2 = (sw = validatePassword2Sw) => {
    if (sw) {
      if (password2 === '') {
        return 'Required field';
      }

      if (password2 !== password) {
        return `Password 2 does not match`;
      }
    }

    return '';
  }

  const passwordError = validatePassword();
  const password2Error = validatePassword2();

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    setValidatePasswordSw(true);
  };

  const handlePassword2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword2(event.target.value);
    setValidatePassword2Sw(true);
  };

  const handleDisplayPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayPassword(event.target.checked);
  };

  const [executeChangePassword, setExecuteChangePassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (executeChangePassword) {
      fetch(`${apiBaseUrl}/change-password.php`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          [secHeaderKey]: sessionData.sec
        },        
        body: JSON.stringify({
          password
        })
      })
        .then(response => {
          if (response.status === 401) {
            navigate('/login');
          } else if(response.status!==200) {
            throw Error(`${response.status} - ${response.statusText}`);
          } else {
            return response.json();
          }
        })
        .then(
          response => {
            setIsLoading(false);
            if (response.success) {
              setPageResult({
                title: 'Success',
                message: 'Your password has been changed.'
              });
            } else {
              if (typeof response.message === 'string') {
                setPageResult({
                  title: 'Error',
                  message: `An error has occurred: ${response.message}`
                });
              } else {
                setPageResult({
                  title: 'Error',
                  message: `An unknown error has occurred.`
                });
              }
              console.error('Change password error', response);
            }
          },
          errorData => {
            setIsLoading(false);
            setPageResult({
              title: 'Server Error',
              message: `An internal server error has occurred.`
            });
            console.error('Change password server error', errorData);
          }
        );
      setExecuteChangePassword(false);
      setIsLoading(true);
    }
  }, [ executeChangePassword ]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    
    setValidatePasswordSw(true);
    setValidatePassword2Sw(true);

    if (validatePassword(true)) {
      return;
    }
    if (validatePassword2(true)) {
      return;
    }

    setExecuteChangePassword(true);
  };  

  const handleClose = () => {
    navigate(`/console`);
  }

  const handleShowResultClose = () => {
    if (pageResult!.title === 'Success') {
      navigate(`/console`);
    } else {
      setPageResult(null);
    }
  }

  const passwordStrengthDesc = passwordStrength(password).value;
  let passwordStrengthPerc = 0;
  let passwordStrengthColor: any = 'error';
  switch (passwordStrengthDesc) {
    case 'Too weak':
      if (password.length > 0) {
        passwordStrengthPerc = 25;
      } else {
        passwordStrengthPerc = 0;
      }
      passwordStrengthColor = 'error';
      break;
    case 'Weak':
      passwordStrengthPerc = 50;
      passwordStrengthColor = 'warning';
      break;
    case 'Medium':
      passwordStrengthPerc = 75;
      passwordStrengthColor = 'info';
      break;
    case 'Strong':
      passwordStrengthPerc = 100;
      passwordStrengthColor = 'success';
      break;   
  }

  return (
    <ConsolePage title='Change Password'>
      <ConsolePageBody>
        <Box component='form' autoComplete='off' noValidate onSubmit={handleSubmit}>
          <Grid container>
            <Grid item lg={8}>
              <Paper sx={{ p: 2 }} elevation={3} >              
                <TextField id='txtPassword' label='New password *' variant='filled'
                  type={ displayPassword ? 'text' : 'password' }
                  autoComplete='new-password'
                  fullWidth autoFocus={!smallDevice}
                  value={password}
                  onChange={handlePasswordChange}
                  helperText={passwordError}
                  error={passwordError !== ''}
                  inputProps={{ maxLength: maxLen }}
                />

                <TextField id='txtPassword2' label='New password (retype) *' variant='filled'
                  type={ displayPassword ? 'text' : 'password'}
                  autoComplete='off'
                  fullWidth sx={{ mt: 2 }}
                  value={password2}
                  onChange={handlePassword2Change}
                  helperText={password2Error}
                  error={password2Error !== ''}
                  inputProps={{ maxLength: maxLen }}
                />   
                
                <Box my={5} mx={1.5}>
                  <Typography component='div' variant='caption'>
                    Password Strength: &nbsp;
                    <strong>{passwordStrengthDesc}</strong>
                  </Typography>                  
                  <LinearProgress color={passwordStrengthColor} variant='determinate' value={passwordStrengthPerc} />
                </Box>

                <Box>                  
                  { sessionData.enablePasswordPolicy && (
                    <>
                    <InfoIcon sx={{float: 'left'}} color='info' fontSize='large'  />
                    <Typography component='div' variant='body2' ml={6}>
                      The password must be at least <strong>{ minLen } characters long</strong>,
                      the first must be a letter and there must be at least one capital letter,
                      one lowercase, one digit and one special character from the following:
                      &nbsp;&nbsp;&nbsp;
                      <pre style={{ fontSize: 16, display: 'inline', fontWeight: 'bold' }}>{charSpecial}</pre>
                    </Typography>
                    </>
                  )}
                  { !sessionData.enablePasswordPolicy && (
                    <>
                    <InfoIcon sx={{float: 'left'}} color='info' fontSize='large'  />
                    <Typography component='div' variant='body2' ml={6}>
                      Password complexity policy is temporarily disabled.
                    </Typography>
                    </>
                  )}                  
                  <FormControlLabel control={<Checkbox
                    checked={displayPassword}
                    onChange={handleDisplayPasswordChange}
                    />} label='Display password' sx={{ ml: 5 }} />
                </Box>
              </Paper>

              <Box sx={{ p: 2, mt: 1 }}>
                <Button aria-label='save' variant='contained' sx={{mr: 3, mt: 2}} type='submit' fullWidth={smallDevice}>
                  <CheckIcon sx={{mr: 2}} /> Save
                </Button>
                <Button aria-label='cancel' variant='outlined' sx={{mt: 2 }} onClick={handleClose} fullWidth={smallDevice}>
                  <ClearIcon sx={{mr: 2}} /> Cancel
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>  
        <Modal
          open={pageResult !== null}
          onClose={handleShowResultClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box sx={modalBoxStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              { pageResult?.title }
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            { pageResult?.message }
            </Typography>
            <Button aria-label='continue' variant='contained' onClick={handleShowResultClose} sx={{mt: 3}}>
              <PlayArrowIcon sx={{ mr: 2 }} /> Continue
            </Button>
          </Box>
        </Modal>    
        {isLoading && <WaitLoading />}
      </ConsolePageBody>
    </ConsolePage>
  );
}