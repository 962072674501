import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export const GridBool = (props: { value: boolean | string | number | undefined }) => {
  const yes = props.value === true || props.value === '1' || props.value === 1;

  if (yes) {
    return (
      <CheckBoxIcon color='success'></CheckBoxIcon>
    );
  } else {
    return (
      <CheckBoxOutlineBlankIcon color='disabled' sx={{ opacity: 0.3 }}></CheckBoxOutlineBlankIcon>
    );
  }
}
