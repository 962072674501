import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RedoIcon from '@mui/icons-material/Redo';
import ReplayIcon from '@mui/icons-material/Replay';
import SmsIcon from '@mui/icons-material/Sms';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import ConsolePage from '../../components/ConsolePage';
import ConsolePageBody from '../../components/ConsolePageBody';
import WaitLoading from '../../components/WaitLoading';
import { apiBaseUrl, modalBoxStyle, secHeaderKey } from '../../consts';
import { GuestData } from '../../data';
import { activeSessionState } from '../../state';

function WaitSkeleton1() {
  return (
    <>
      <Skeleton animation="wave" variant='text' />
      <Skeleton animation="wave" variant='text' />
      <Skeleton animation="wave" variant='text' />
    </>
  );
}

function WaitSkeleton2() {
  return (
    <>
      <Skeleton animation="wave" variant='text' />
      <Skeleton animation="wave" variant='text' />
    </>
  );
}

function Status(props: {
  expiration: number
}) {
  if (props.expiration >= 7) {
    return (<Typography component='p' variant='h6' color='success.main'>EXPIRING IN &nbsp;<strong>{props.expiration}</strong>&nbsp; DAYS</Typography>)
  }
  if (props.expiration >= 2) {
    return (<Typography component='p' variant='h6' color='warning.main'>EXPIRING IN &nbsp;<strong>{props.expiration}</strong>&nbsp; DAYS</Typography>)
  }
  if (props.expiration >= 1) {
    return (<Typography component='p' variant='h6' color='warning.main'>EXPIRING IN &nbsp;<strong>1</strong>&nbsp; DAY</Typography>)
  }
  return (<Typography component='p' variant='h6' color='error'>DISABLED</Typography>)
}

export default function MyGuestDetails() {
  const navigate = useNavigate();
  const { phone } = useParams<{
    phone: string
  }>();

  const smallDevice = !useMediaQuery('(min-width:600px)');

  const sessionData = useRecoilValue(activeSessionState);
  const [guest, setGuest] = useState<GuestData | null>(null);
    
  const [executeLoadGuest, setExecuteLoadGuest] = useState(true);
  const [executeResendPassword, setExecuteResendPassword] = useState(false);
  const [executePostponeExpiration, setExecutePostponeExpiration] = useState(false);
  const [executeDisable, setExecuteDisable] = useState(false);

  const [resendPassowrdConfirmationOpen, setResendPassowrdConfirmationOpen] = useState(false);
  const [postponeExpirationConfirmationOpen, setPostponeExpirationConfirmationOpen] = useState(false);
  const [disableConfirmationOpen, setDisableConfirmationOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [pageResult, setPageResult] = useState<{
    title: string;
    message: string;
    onClose: () => void
  } | null>(null);

  const close = () => {
    navigate(`/console/my-guests`);
  }

  const handleResendPassword = () => {
    setResendPassowrdConfirmationOpen(true);
  }

  const handlePostponeExpiration = () => {
    setPostponeExpirationConfirmationOpen(true);
  }

  const handleDisable = () => {
    setDisableConfirmationOpen(true);
  }

  const handleResendPasswordConfirmationDialogClose = (confirmed: boolean) => {
    setResendPassowrdConfirmationOpen(false);
    if (confirmed) {
      setExecuteResendPassword(true);
    }
  }

  const handlePostponeExpirationConfirmationDialogClose = (confirmed: boolean) => {
    setPostponeExpirationConfirmationOpen(false);
    if (confirmed) {
      setExecutePostponeExpiration(true);
    }
  }

  const handleDisableConfirmationDialogClose = (confirmed: boolean) => {
    setDisableConfirmationOpen(false);
    if (confirmed) {
      setExecuteDisable(true);
    }
  }

  useEffect(() => {
    if (executeLoadGuest) {
      setTimeout(() => {
        fetch(`${apiBaseUrl}/my-guest-details.php`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            [secHeaderKey]: sessionData.sec
          },
          body: JSON.stringify({ phone })
        })
          .then(response => {
            if (response.status === 401) {
              navigate('/login');
            } else if (response.status !== 200) {
              throw Error(`${response.status} - ${response.statusText}`);
            } else {
              return response.json();
            }
          })
          .then(
            response => {
              if (response.success) {
                setGuest(response.guest);
              } else {
                if (typeof response.message === 'string') {
                  setPageResult({
                    title: 'Error',
                    message: `An error has occurred: ${response.message}`,
                    onClose: () => close()
                  });
                } else {
                  setPageResult({
                    title: 'Error',
                    message: `An unknown error has occurred.`,
                    onClose: () => close()
                  });
                }
                console.error('Load my-guest error', response);
              }
            });
      }, 100);
      setExecuteLoadGuest(false);
    }

    if (executeResendPassword) {
      setTimeout(() => {
        fetch(`${apiBaseUrl}/resend-my-guest-password.php`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            [secHeaderKey]: sessionData.sec
          },
          body: JSON.stringify({ phone })
        })
          .then(response => {
            setIsLoading(false);
            if (response.status === 401) {
              navigate('/login');
            } else if (response.status !== 200) {
              throw Error(`${response.status} - ${response.statusText}`);
            } else {
              return response.json();
            }
          })
          .then(
            response => {
              if (response.success) {
                setPageResult({
                  title: 'Success',
                  message: `New password has been sent via SMS to the phone number ${phone}.`,
                  onClose: () => setPageResult(null)
                });
              } else {
                if (typeof response.message === 'string') {
                  setPageResult({
                    title: 'Error',
                    message: `An error has occurred: ${response.message}`,
                    onClose: () => setPageResult(null)
                  });
                } else {
                  setPageResult({
                    title: 'Error',
                    message: `An unknown error has occurred.`,
                    onClose: () => setPageResult(null)
                  });
                }
                console.error('Resend my-guest password error', response);
              }
            });
      }, 5000);
      setExecuteResendPassword(false);
      setIsLoading(true);
    }

    if (executePostponeExpiration) {
      setTimeout(() => {
        const prevExpiration = guest?.expiration;
        fetch(`${apiBaseUrl}/postpone-my-guest-expiration.php`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            [secHeaderKey]: sessionData.sec
          },
          body: JSON.stringify({ phone })
        })
          .then(response => {
            setIsLoading(false);
            if (response.status === 401) {
              navigate('/login');
            } else if (response.status !== 200) {
              throw Error(`${response.status} - ${response.statusText}`);
            } else {
              return response.json();
            }
          })
          .then(
            response => {
              if (response.success) {
                setExecuteLoadGuest(true);
                setPageResult({
                  title: 'Success',
                  message: (prevExpiration! > 0 ? 'Guest expiration has been updated.' : 'Guest has been re-activated.'),
                  onClose: () => setPageResult(null)
                });
              } else {
                if (typeof response.message === 'string') {
                  setPageResult({
                    title: 'Error',
                    message: `An error has occurred: ${response.message}`,
                    onClose: () => setPageResult(null)
                  });
                } else {
                  setPageResult({
                    title: 'Error',
                    message: `An unknown error has occurred.`,
                    onClose: () => setPageResult(null)
                  });
                }
                console.error('Postpone my-guest expiration error', response);
              }
            });
      }, 50);
      setExecutePostponeExpiration(false);
      setIsLoading(true);
    }

    if (executeDisable) {
      setTimeout(() => {
        fetch(`${apiBaseUrl}/disable-my-guest.php`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            [secHeaderKey]: sessionData.sec
          },
          body: JSON.stringify({ phone })
        })
          .then(response => {
            setIsLoading(false);
            if (response.status === 401) {
              navigate('/login');
            } else if (response.status !== 200) {
              throw Error(`${response.status} - ${response.statusText}`);
            } else {
              return response.json();
            }
          })
          .then(
            response => {
              if (response.success) {
                setExecuteLoadGuest(true);
                setPageResult({
                  title: 'Success',
                  message: `Guest has been disabled.`,
                  onClose: () => setPageResult(null)
                });
              } else {
                if (typeof response.message === 'string') {
                  setPageResult({
                    title: 'Error',
                    message: `An error has occurred: ${response.message}`,
                    onClose: () => setPageResult(null)
                  });
                } else {
                  setPageResult({
                    title: 'Error',
                    message: `An unknown error has occurred.`,
                    onClose: () => setPageResult(null)
                  });
                }
                console.error('Disable my-guest error', response);
              }
            });
      }, 50);
      setExecuteDisable(false);
      setIsLoading(true);
    }
  }, [ executeLoadGuest, executeResendPassword, executePostponeExpiration, executeDisable ]); 
 
  return (
    <ConsolePage title='My Guest Details'>
      <ConsolePageBody>
        <Box>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <Paper sx={{ p: 2, height: '100%'  }} elevation={3} >        
              {guest === null && <WaitSkeleton1 />}
              {guest !== null && (<>                
                <Typography component='p' variant='subtitle1'>
                  Given Name:
                </Typography>
                <Typography component='p' variant='h5'>
                  { guest.givenname }
                </Typography>

                <Typography component='p' variant='subtitle1' mt={2}>
                  Surname:
                </Typography>
                <Typography component='p' variant='h5'>
                { guest.surname }
                </Typography>

                <Divider sx={{my: 3}} />

                <Typography component='p' variant='subtitle1'>
                  Phone:
                </Typography>
                <Typography component='p' variant='h5'>
                  { phone }
                </Typography>

                <Typography component='p' variant='subtitle1' mt={2}>
                  Email:
                </Typography>
                <Typography component='p' variant='h5'>
                { guest.email }
                </Typography>

                 
                {guest.note && (<>
                  <Divider sx={{ my: 3 }} />

                  <Typography component='p' variant='subtitle1'>
                    Note:
                  </Typography>
                  <Typography component='p' variant='h5' mb={3}>
                  { guest.note }
                </Typography></>) }
                </>)}
              </Paper>
            </Grid>
            <Grid item lg={6} xs={12}>
              <Paper sx={{ p: 2, height: '100%' }} elevation={3}>
                {guest === null && <WaitSkeleton2 />}
                {guest !== null && (<>
                  <Typography component='p' variant='subtitle1'>
                    Username:
                  </Typography>
                  <Typography component='p' variant='h5'>
                    { guest.username }
                  </Typography>

                  <Typography component='p' variant='subtitle1' mt={2}>
                    Password:
                  </Typography>
                  <Typography component='p' variant='h5'>
                    <Button aria-label='sendPassword' variant='contained' onClick={handleResendPassword} sx={{mt: 1, mb: 2}} fullWidth={smallDevice}>
                      <SmsIcon sx={{mr: 2}} /> Re-Send via SMS
                    </Button>
                  </Typography>     
                  
                  <Divider sx={{ my: 3 }} />

                  <Typography component='p' variant='subtitle1'>
                    Creation Time:
                  </Typography>
                  <pre style={{ fontSize: 24, margin: 0 }}>
                    { guest.createdOn }
                  </pre>

                  <Typography component='p' variant='subtitle1' mt={2}>
                    Expiration Time:
                  </Typography>
                  <pre style={{ fontSize: 24, margin: 0 }}>
                    { guest.disabledOn }
                  </pre>

                  <Typography component='p' variant='subtitle1' mt={2}>
                    Status:
                  </Typography>
                  <Status expiration={guest.expiration} />

                  
                  {guest.expiration > 0 && (
                    <Stack my={3} direction={ smallDevice ? 'column' : 'row' } spacing={2}>
                      <Button aria-label='postponeExpiration' variant='contained' onClick={handlePostponeExpiration}
                        disabled={guest.expiration >= 30}  fullWidth={smallDevice}>
                        <RedoIcon sx={{ mr: 2 }} /> Postpone Expiration
                      </Button>
                      <Button aria-label='disableNow' variant='contained' onClick={handleDisable} color='error'  fullWidth={smallDevice}>
                        <DeleteIcon sx={{ mr: 2 }} /> Disable
                      </Button>
                    </Stack>                  
                  )}

                  {guest.expiration <= 0 && (
                    <Stack my={3} direction='row' spacing={2}>
                      <Button aria-label='postponeExpiration' variant='contained' onClick={handlePostponeExpiration} color='success'  fullWidth={smallDevice}>
                        <ReplayIcon sx={{ mr: 2 }} /> Re-Enable
                      </Button>
                    </Stack>                  
                  )}
                </>)}
              </Paper>
            </Grid>
          </Grid>
          <Box sx={{ p: 2, mt: 3 }}>
            <Button aria-label='view' variant='outlined' onClick={close}  fullWidth={smallDevice}>
              <ClearIcon sx={{mr: 2}} /> Close Guest Details
            </Button>
          </Box>          
        </Box>
        
        <Modal
          open={pageResult !== null}
          onClose={pageResult?.onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box sx={modalBoxStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              { pageResult?.title }
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            { pageResult?.message }
            </Typography>
            <Button aria-label='continue' variant='contained' onClick={pageResult?.onClose} sx={{mt: 3}}>
              <PlayArrowIcon sx={{ mr: 2 }} /> Continue
            </Button>
          </Box>
        </Modal>      
        
        {isLoading && <WaitLoading />}

        <ConfirmationDialog
          open={resendPassowrdConfirmationOpen} title='RE-SEND PASSWORD VIA SMS' message={`Are you sure you want to create a new password and send it to the phone number ${phone} ?`} 
        onClose={handleResendPasswordConfirmationDialogClose} />

        {guest && (
          <ConfirmationDialog
          open={postponeExpirationConfirmationOpen} title={guest.expiration > 0 ? 'POSTPONE EXPIRATION' : 'ENABLE GUEST'}
            message={guest.expiration > 0 ? 'Are you sure you want to set guest expiration in 30 days from today ?'
              : 'Are you sure you want to enable this guest ?'}
          onClose={handlePostponeExpirationConfirmationDialogClose} />        
        )}
        
        <ConfirmationDialog
          open={disableConfirmationOpen} title='DISABLE GUEST' message='Are you sure you want to disable this guest ?'
          onClose={handleDisableConfirmationDialogClose} />
        
      </ConsolePageBody>
    </ConsolePage>
  );
}