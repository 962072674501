import AddIcon from '@mui/icons-material/Add';
import PageviewIcon from '@mui/icons-material/Pageview';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ConsolePage from '../../components/ConsolePage';
import ConsolePageBody from '../../components/ConsolePageBody';
import { apiBaseUrl, secHeaderKey } from '../../consts';
import { GuestData } from '../../data';
import { activeSessionState } from '../../state';

interface ViewButtonProps {
  id: string;
  hasFocus: boolean;
  onClick: (id: string) => void;
}

const ViewButton = (props: ViewButtonProps) => {
  const openGuest = () => {
    props.onClick(props.id);
  }

  return (
    <Button aria-label='view'
      variant='outlined'
      size='small'
      color='secondary'
      tabIndex={props.hasFocus ? 0 : -1} onClick={openGuest} >
      <PageviewIcon sx={{mr: 1}} /> Details...
    </Button>
  );
}

function WaitSkeleton() {
  return (
    <>
      <Skeleton animation="wave" variant='text' />
      <Skeleton animation="wave" variant='text' />
      <Skeleton animation="wave" variant='text' />
    </>
  );
}

function Status(props: {
  expiration: number
}) {
  if (props.expiration >= 7) {
    return (<Typography component='p' variant='subtitle2' color='success.main'>EXPIRING IN &nbsp;<strong>{props.expiration}</strong>&nbsp; DAYS</Typography>)
  }
  if (props.expiration >= 2) {
    return (<Typography component='p' variant='subtitle2' color='warning.main'>EXPIRING IN &nbsp;<strong>{props.expiration}</strong>&nbsp; DAYS</Typography>)
  }
  if (props.expiration >= 1) {
    return (<Typography component='p' variant='subtitle2' color='warning.main'>EXPIRING IN &nbsp;<strong>1</strong>&nbsp; DAY</Typography>)
  }
  return (<Typography component='p' variant='subtitle2' color='error'>DISABLED</Typography>)
}

export default function MyGuestList() {
  const navigate = useNavigate();

  const sessionData = useRecoilValue(activeSessionState);
  const [ guests, setGuests ] = useState<GuestData[] | null>(null);

  const handleNewGuest = () => {
    navigate(`/console/my-guests/new`);
  }

  const handleOpenGuest = (id: string) => {
    const guest = guests!.find(guest => guest.id === id);
    navigate(`${guest!.phone}`);
  }

  const cols: GridColDef[] = [
    { field: 'act', headerName: '', width: 145, renderCell: (params: GridRenderCellParams<string>) => (
      <ViewButton id={params.id.toString()} hasFocus={params.hasFocus} onClick={handleOpenGuest} />
    ), },  
    { field: 'givenname', headerName: 'Given Name', width: 200 },
    { field: 'surname', headerName: 'Surname', width: 200 },
    // { field: 'phone', headerName: 'Phone', width: 200 },
    // { field: 'email', headerName: 'Email', width: 250 },
    { field: 'note', headerName: 'Note', width: 200, flex: 1, },
    { field: 'createdOn', headerName: 'Creation Time', width: 200 },
    { field: 'disabledOn', headerName: 'Expiration Time', width: 200 },
    { field: 'expiration', headerName: 'Status', width: 180, renderCell: (params: GridRenderCellParams<number>) => (
      <Status expiration={params.value!} />
    ), },
  ];

  useEffect(() => {
    setTimeout(() => {
      fetch(`${apiBaseUrl}/my-guests.php`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          [secHeaderKey]: sessionData.sec
        }
      })
        .then(response => {
          if (response.status === 401) {
            navigate('/login');
          } else if(response.status!==200) {
            throw Error(`${response.status} - ${response.statusText}`);
          } else {
            return response.json();
          }
        })
        .then(
          response => {
            if (response.success) {
              const guests: GuestData[] = response.guests;
              
              // Mostra prima le scadenze ravvicinate, e in fondo quelli disabilitati
              const sortedGuests = guests.map(guest => ({
                ...guest,
                sort: guest.expiration <= 0 ? 100000 : guest.expiration
              })).sort((a, b) => {
                if (a.sort === b.sort) {
                  return new Date(a.disabledOn).getTime() - new Date(b.disabledOn).getTime();
                }
                return a.sort - b.sort
              })
              setGuests(sortedGuests);
            } else {
              console.error('Error loading my-guests.');
            }
        });  
    }, 100);
  }, []); 

  return (
    <ConsolePage title='My Guests'>
      <ConsolePageBody>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%'}}>
          <Paper sx={{ p: 2, flexGrow: 1 }} elevation={3} > 
            {guests === null && <WaitSkeleton />}
            {guests !== null && <DataGrid rows={guests} columns={cols} />}
          </Paper>
          <Box sx={{ p: 2, mt: 3 }}>  
            <Button aria-label='new-guest' variant='contained' sx={{mr: 3}} onClick={handleNewGuest}>
              <AddIcon sx={{mr: 2}} /> New Guest
            </Button>        
          </Box>
        </Box>
      </ConsolePageBody>        
    </ConsolePage>
  );
}