import Paper from '@mui/material/Paper';

export default function ConsolePageBody({ children }: {
  children: React.ReactNode
}) {
  return (
    <Paper sx={{ p: 2, flexGrow: 1 }}>
      { children }
    </Paper>
  );
}
