import AddIcon from '@mui/icons-material/Add';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import ConsolePage from '../../components/ConsolePage';
import ConsolePageBody from '../../components/ConsolePageBody';
import UserDevices from '../../components/UserDevices';
import WaitLoading from '../../components/WaitLoading';
import { apiBaseUrl, modalBoxStyle, secHeaderKey, UserLevel } from '../../consts';
import { DeviceData, UserData } from '../../data';
import { activeSessionState, permissionSelector } from '../../state';
import { adaptAffiliation, adaptUserType, formatInfo, formatUserLevel, getUserLevel, getVlanDescName, hasRoles, isCardsUser } from '../../utils/adapters';

function WaitSkeleton1() {
  return (
    <>
      <Skeleton animation="wave" variant='text' />
      <Skeleton animation="wave" variant='text' />
      <Skeleton animation="wave" variant='text' />
    </>
  );
}

function WaitSkeleton2() {
  return (
    <>
      <Skeleton animation="wave" variant='text' />
      <Skeleton animation="wave" variant='text' />
    </>
  );
}

function Status(props: {
  enabled: boolean
  disabledOn: string
}) {
  if (props.enabled) {
    return (<Typography component='p' variant='h6' color='success.main' mb={2}>ACTIVE</Typography>)
  }
  return (<Typography component='p' variant='h6' color='error' mb={2}>DISABLED ON &nbsp;<strong>{ props.disabledOn }</strong></Typography>)
}

export default function UserDetails() {
  const navigate = useNavigate();
  const { userName } = useParams<{
    userName: string
  }>();

  const smallDevice = !useMediaQuery('(min-width:600px)');
  
  const sessionData = useRecoilValue(activeSessionState);
  const permissionData = useRecoilValue(permissionSelector);
  const [user, setUser] = useState<UserData | null>(null);
  const [devices, setDevices] = useState<DeviceData[] | null>(null);
    
  const [displayAllDetails, setDisplayAllDetails] = useState(false);
  const [expandDeviceGrid, setExpandDeviceGrid] = useState(false);

  const [executeLoadUser, setExecuteLoadUser] = useState(true);
  const [executeResetPassword, setExecuteResetPassword] = useState(false);
  const [executeDownloadDocId, setExecuteDownloadDocId] = useState(false);

  const [resetPassowrdConfirmationOpen, setResetPassowrdConfirmationOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [pageResult, setPageResult] = useState<{
    title: string;
    message: string;
    onClose: () => void
  } | null>(null);

  const handleSwapDisplayAllDetails = () => {
    setDisplayAllDetails(!displayAllDetails);
  }

  const handleSwapExpandDeviceGrid = () => {
    setExpandDeviceGrid(!expandDeviceGrid);
  }

  const handleNewDevice = () => {
    navigate(`new-device`);
  }

  const close = () => {
    navigate(`/console/users`);
  }

  const handleResetPassword = () => {
    setResetPassowrdConfirmationOpen(true);
  }

  const handleDownlodadDocId = () => {
    setExecuteDownloadDocId(true);
  }

  const handleResetPasswordConfirmationDialogClose = (confirmed: boolean) => {
    setResetPassowrdConfirmationOpen(false);
    if (confirmed) {
      setExecuteResetPassword(true);
    }
  }

  useEffect(() => {
    if (executeLoadUser) {
      setTimeout(() => {
        fetch(`${apiBaseUrl}/user-details.php`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            [secHeaderKey]: sessionData.sec
          },
          body: JSON.stringify({ userName })
        })
          .then(response => {
            if (response.status === 401) {
              navigate('/login');
            } else if (response.status !== 200) {
              throw Error(`${response.status} - ${response.statusText}`);
            } else {
              return response.json();
            }
          })
          .then(
            response => {
              if (response.success) {
                setUser(response.userDetails.user);
                setDevices(response.userDetails.devices);
              } else {
                if (typeof response.message === 'string') {
                  setPageResult({
                    title: 'Error',
                    message: `An error has occurred: ${response.message}`,
                    onClose: () => close()
                  });
                } else {
                  setPageResult({
                    title: 'Error',
                    message: `An unknown error has occurred.`,
                    onClose: () => close()
                  });
                }
                console.error('Load user error', response);
              }
            });
      }, 100);
      setExecuteLoadUser(false);
    }

    if (executeResetPassword) {
      setTimeout(() => {
        fetch(`${apiBaseUrl}/reset-user-password.php`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            [secHeaderKey]: sessionData.sec
          },
          body: JSON.stringify({ userName: user!.userName })
        })
          .then(response => {
            setIsLoading(false);
            if (response.status === 401) {
              navigate('/login');
            } else if (response.status !== 200) {
              throw Error(`${response.status} - ${response.statusText}`);
            } else {
              return response.json();
            }
          })
          .then(
            response => {
              if (response.success) {
                setPageResult({
                  title: 'Success',
                  message: `Password has been sent to email address ${user!.email}.`,
                  onClose: () => setPageResult(null)
                });
              } else {
                if (typeof response.message === 'string') {
                  setPageResult({
                    title: 'Error',
                    message: `An error has occurred: ${response.message}`,
                    onClose: () => setPageResult(null)
                  });
                } else {
                  setPageResult({
                    title: 'Error',
                    message: `An unknown error has occurred.`,
                    onClose: () => setPageResult(null)
                  });
                }
                console.error('Reset user password error', response);
              }
            });
      }, 2000);
      setExecuteResetPassword(false);
      setIsLoading(true);
    }

    if (executeDownloadDocId) {
      setTimeout(() => {
        fetch(`${apiBaseUrl}/download-user-docid.php`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            [secHeaderKey]: sessionData.sec
          },
          body: JSON.stringify({ userName: user!.userName, docId: user!.docId })
        })
          .then(response => {
            setIsLoading(false);
            if (response.status === 401) {
              navigate('/login');
            } else if (response.status !== 200) {
              throw Error(`${response.status} - ${response.statusText}`);
            } else {
              if (response.headers.get('Pragma') === 'public') {
                return response.blob();
              } else {
                return response.json();
              }
            }
          }, reject => {
            setIsLoading(false);
            setPageResult({
              title: 'Error',
              message: `An error has occurred: ${reject}`,
              onClose: () => setPageResult(null)
            });
          }).then(response => {
            if (response) {
              if (typeof response.message === 'string') {
                setPageResult({
                  title: 'Error',
                  message: `An error has occurred: ${response.message}`,
                  onClose: () => setPageResult(null)
                });
                console.error('Downlod DocID error', response);
              } else {
                var url = window.URL.createObjectURL(response);
                var a = document.createElement('a');
                a.href = url;
                a.download = user!.docId;
                document.body.appendChild(a); // Workaround for firefox
                a.click();    
                a.remove();
              }
            }
          }, (reason: any) => {
            setPageResult({
              title: 'Error',
              message: `An error has occurred: ${reason}`,
              onClose: () => setPageResult(null)
            });
          })
      }, 1);
      setExecuteDownloadDocId(false);
      setIsLoading(true);
    }

  }, [executeLoadUser, executeResetPassword, executeDownloadDocId]); 
  
  const userLevel = user ? getUserLevel(user) : UserLevel.user;
 
  return (
    <ConsolePage title='User Details'>
      <ConsolePageBody>
        <Box>
            <Grid container spacing={2}>
              <Grid item xl={4} lg={12} xs={12}>
                <Paper sx={{ p: 2, height: '100%' }} elevation={3} >        
                  {user === null && <WaitSkeleton1 />}
                  {user !== null && (<>                
                    <Typography component='p' variant='subtitle1'>
                      Given Name:
                    </Typography>
                    <Typography component='p' variant='h5'>
                      { user.givenName }
                    </Typography>

                    <Typography component='p' variant='subtitle1' mt={2}>
                      Surname:
                    </Typography>
                    <Typography component='p' variant='h5'>
                    { user.surname }
                    </Typography>

                    <Collapse in={displayAllDetails}>
                      <Divider sx={{my: 3}} />

                      <Typography component='p' variant='subtitle1'>
                        Phone:
                      </Typography>
                      <pre style={{ fontSize: 24, margin: 0 }}>
                        { user.phone }
                      </pre>   

                      <Typography component='p' variant='subtitle1' mt={2}>
                        Email:
                      </Typography>
                      <Typography component='p' variant='h5' mb={2}>
                      { user.email }
                      </Typography>
                        
                      {user.note && (<>
                        <Divider sx={{ my: 3 }} />
                          
                        <Typography component='p' variant='subtitle1'>
                          Note:
                        </Typography>
                        <Typography component='p' variant='h5' mb={2}>
                        { user.note }
                      </Typography></>) }
                    </Collapse>

                    </>)}
                </Paper>
              </Grid>

              <Grid item xl={4} lg={6} xs={12}>
                <Paper sx={{ p: 2, height: '100%' }} elevation={3}>
                  {user === null && <WaitSkeleton2 />}
                  {user !== null && (<>
                    <Typography component='p' variant='subtitle1'>
                      Username:
                    </Typography>
                    <Typography component='p' variant='h5'>
                      { user.userName }
                    </Typography>
                    
                    <Typography component='p' variant='subtitle1' mt={2}>
                        Password:
                      </Typography>
                      <Typography component='p' variant='h5'>
                        <Button aria-label='resetPassword' variant='contained' sx={{mt: 1, mb: 2}} onClick={handleResetPassword} fullWidth={smallDevice}>
                          <RestartAltIcon sx={{mr: 2}} /> Reset
                        </Button>
                      </Typography>  

                    <Collapse in={displayAllDetails}>
                      <Divider sx={{ my: 2, mb: 3 }} />

                      <Typography component='p' variant='subtitle1'>
                        Creation Time:
                      </Typography>
                      <pre style={{ fontSize: 24, margin: 0 }}>
                        { user.createdOn }
                      </pre>

                      <Typography component='p' variant='subtitle1' mt={2}>
                        Creator:
                      </Typography>
                      <Typography component='p' variant='h5'>
                        { user.creator }
                      </Typography>                

                      { (!user.enabled) && ( <>
                      <Typography component='p' variant='subtitle1' mt={2}>
                        Expiration Time:
                      </Typography>
                      <pre style={{ fontSize: 24, margin: 0 }}>
                        { user.disabledOn }
                      </pre>
                      </> )}

                      <Typography component='p' variant='subtitle1' mt={2}>
                        Status:
                      </Typography>
                      <Status enabled={user.enabled} disabledOn={user.disabledOn} />
                    </Collapse>
                  </>)}
                </Paper>
              </Grid>
              
              <Grid item xl={4} lg={6} xs={12}>
                <Paper sx={{ p: 2, height: '100%' }} elevation={3} >        
                {user === null && <WaitSkeleton1 />}
                {user !== null && (<>                                 
                  <Typography component='p' variant='subtitle1'>
                    Affiliation:
                  </Typography>
                  <Typography component='p' variant='h5' mb={2}>
                  { adaptAffiliation(user.affiliation) }
                  </Typography>
                  
                  <Typography component='p' variant='subtitle1' mt={2}>
                    Type:
                  </Typography>
                  <Typography component='p' variant='h5' mb={1}>
                  { adaptUserType(user.userType) }
                  </Typography>    
                    
                  <Collapse in={displayAllDetails}>
                    <Divider sx={{ mt: 3, mb: 2 }} />

                    {user.docId && (<>
                      <Typography component='p' variant='subtitle1' mt={3}>
                      Document:
                      </Typography>
                      <Typography component='p' variant='h5'>
                        { user.docId }
                      </Typography>                    
                      {permissionData.canDownloadDocId && (
                        <Typography component='p' variant='h5'>
                          <Button aria-label='downloadDoc' variant='contained' sx={{ mt: 1, mb: 1 }} onClick={handleDownlodadDocId} fullWidth={smallDevice}>
                            <DownloadIcon sx={{ mr: 2 }} /> Download
                          </Button>
                        </Typography>
                      )}
                    </>)}
                      

                    <Grid container>
                        <Grid item xs={6}>
                          <Typography component='p' variant='subtitle1' mt={2}>
                            User Id:
                          </Typography>
                          <Typography component='p' variant='h5'>
                            { formatInfo(user.id) }
                          </Typography>                       
                      </Grid>
                        <Grid item xs={6}>               
                              { isCardsUser(user.userType, user.cardsUserId) && (<> <Typography component='p' variant='subtitle1' mt={2}>
                            User Id Cards:
                          </Typography>
                          <Typography component='p' variant='h5'>
                          { formatInfo(user.cardsUserId) }
                          </Typography> </>)}                    
                      </Grid>
                    </Grid>

                    {permissionData.canManageMultipleVLans && (
                        <>
                          <Typography component='p' variant='subtitle1' mt={2}>
                            VLAN:
                          </Typography>
                          <Box sx={{mb: 2}}>
                            <pre style={{ fontSize: 24, margin: 0 }}>                            
                            { getVlanDescName(user.vlan) }
                            </pre>   
                          </Box>                      
                        </>
                      )}                  
                                                      
                      {hasRoles(user) && (<>
                        <Divider sx={{ my: 3 }} />

                        <Typography component='p' variant='subtitle1' mt={2}>
                          Roles:
                        </Typography>
                        <Typography component='p' variant='h5' mb={2}>
                          <Stack direction="row" spacing={1} sx={{mt: 1, mb: 2}}>

                            {userLevel >= UserLevel.manager && (
                              <Chip color="secondary" label={formatUserLevel(userLevel)} icon={<BadgeOutlinedIcon />} variant='outlined' />
                            ) }
                            {user.canMakeGuest && (
                              <Chip color="secondary" label="GUEST SUPERVISOR" icon={<SupervisorAccountOutlinedIcon  />} variant='outlined' />                        
                            ) }
                          </Stack>
                        </Typography>                    
                      </>)}  
                    </Collapse>
                  </>)}
                </Paper>
              </Grid>
            </Grid>
          
            <Grid container>
              <Grid item xl={4} lg={6} xs={12}>
                <div style={{ textAlign: 'center' }}>
                  {!displayAllDetails && (
                    <Fab sx={{mt: 3}} variant='extended' size='small' onClick={handleSwapDisplayAllDetails}>
                    <ExpandMoreIcon sx={{ mr: 2 }} />
                    {smallDevice ? 'Expand Details' : 'Show additional user details' } &nbsp;&nbsp;
                    </Fab>             
                  )}
                  {displayAllDetails && (
                    <Fab sx={{mt: 3}} variant='extended' size='small' onClick={handleSwapDisplayAllDetails}>
                    <ExpandLessIcon sx={{ mr: 2 }} />
                    {smallDevice ? 'Collapse Details' : 'Hide additional user details' } &nbsp;&nbsp;                    
                    &nbsp;&nbsp;
                    </Fab>             
                  )}
                </div>
              </Grid>
            <Grid item xl={4} sx={{
              display: { xs: 'none', xl: 'block' }
            }}></Grid>
            <Grid item xl={4} lg={12} sm={12} sx={{
              display: { xs: 'none', xl: 'block' }
            }}>
                <div style={{ textAlign: 'center' }}>
                  {!expandDeviceGrid && (
                    <Fab sx={{mt: 3}} variant='extended' size='small' onClick={handleSwapExpandDeviceGrid}>
                      <ExpandMoreIcon sx={{mr: 2}} /> Expand Device List + Search &nbsp;&nbsp;
                    </Fab>             
                  )}
                  {expandDeviceGrid && (
                    <Fab sx={{mt: 3}} variant='extended' size='small' onClick={handleSwapExpandDeviceGrid}>
                      <ExpandLessIcon sx={{mr: 2}} /> Collapse Device List &nbsp;&nbsp;
                    </Fab>             
                  )}
                </div>
              </Grid>            
            </Grid>

            {devices === null && <WaitSkeleton1 />}
            {user && devices && (
              <UserDevices userName={user!.userName} devices={devices}
              gridHeight={ expandDeviceGrid ? 700 : 316 } canFilter={expandDeviceGrid} />            
            )}
            
            <Box sx={{ p: 2, mt: 1 }}>
              <Button aria-label='new-guest' variant='contained' sx={{mr: 3, mt: 2}} onClick={handleNewDevice} fullWidth={smallDevice}>
                <AddIcon sx={{mr: 2}} /> New Device
              </Button>        
              <Button aria-label='view' variant='outlined' onClick={close} sx={{ mt: 2 }}  fullWidth={smallDevice}>
                <ClearIcon sx={{mr: 2}} /> Close User Details
              </Button>
            </Box>          
        </Box>
        
        <Modal
          open={pageResult !== null}
          onClose={pageResult?.onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          >
          <Box sx={modalBoxStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              { pageResult?.title }
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            { pageResult?.message }
            </Typography>
            <Button aria-label='continue' variant='contained' onClick={pageResult?.onClose} sx={{mt: 3}}>
              <PlayArrowIcon sx={{ mr: 2 }} /> Continue
            </Button>
          </Box>
        </Modal>      

        {isLoading && <WaitLoading />}

        <ConfirmationDialog
          open={resetPassowrdConfirmationOpen} title='RESET PASSWORD' message={`Are you sure you want to create a new password for user ${user?.userName} ?`} 
          onClose={handleResetPasswordConfirmationDialogClose} />
        
      </ConsolePageBody>
    </ConsolePage>
  );
}