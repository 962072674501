import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function ConsolePage({ children, title }: {
  title: string,
  children: React.ReactNode
}) {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      pt: 2,
      pb: 1,
      px: 1
    }}>
      <Typography component='h2' variant='h4' color='primary' mb={0} pl={2}>
        { title }
      </Typography>
      { children }
    </Box>
  );
}
