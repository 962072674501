import { Navigate, Route, Routes } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import RequireSession from './components/RequireSession';
import ChangePassword from './pages/console/ChangePassword';
import Console from './pages/console/Console';
import Dashboard from './pages/console/Dashboard';
import MyDevices from './pages/console/MyDevices';
import MyGuestDetails from './pages/console/MyGuestDetails';
import MyGuestList from './pages/console/MyGuestList';
import NewGuest from './pages/console/NewGuest';
import NewUser from './pages/console/NewUser';
import NewUserDevice from './pages/console/NewUserDevice';
import UserDetails from './pages/console/UserDetails';
import UserList from './pages/console/UserList';
import Login from './pages/Login';
import Logout from './pages/Logout';
import NotFound from './pages/NotFound';
import { permissionSelector, sessionState } from './state';

export default function Router() {  
  const permissionData = useRecoilValue(permissionSelector);

  // Per utenti non loggati aggiunge lo stesso el routes per evitare l'errore 404
  const sessionData = useRecoilValue(sessionState); 

  return (
    <Routes>
      <Route path='/console' element={ <RequireSession>
          <Console />
        </RequireSession>
        }>
        <Route index element={<Dashboard />} />
        {(!sessionData || permissionData.canManageOwnGuests) && ( <Route path='my-guests' element={<MyGuestList />} /> )}
        {(!sessionData || permissionData.canManageOwnGuests) && ( <Route path='my-guests/:phone' element={<MyGuestDetails />} /> )}
        {(!sessionData || permissionData.canManageOwnGuests) && ( <Route path='my-guests/new' element={<NewGuest />} /> )}
        <Route path='my-devices' element={<MyDevices />} />
        <Route path='change-password' element={<ChangePassword />} />
        {(!sessionData || permissionData.canManageUsers) && ( <Route path='users' element={<UserList />} />)}
        {(!sessionData || permissionData.canManageUsers) && ( <Route path="users/:userName" element={<UserDetails />} />)}
        {(!sessionData || permissionData.canManageUsers) && ( <Route path="users/:userName/new-device" element={<NewUserDevice />} /> )}
        {(!sessionData || permissionData.canManageUsers) && ( <Route path="users/new" element={<NewUser />} />)}
      </Route>
      <Route path='/login' element={<Login />} />
      <Route path='/logout' element={<Logout />} />
      <Route path='/' element={<Navigate to='/login' replace />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}