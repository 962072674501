// DEV URL:
// https://dev.ldap2.pd.cnr.it:3000/login

export const apiBaseUrl = 'https://ldap2.pd.cnr.it/api/nam2-v007';

export const secHeaderKey = 'X-Jwt-Sec';

export const usernameSuffix = '@pd.cnr.it';

export const modalBoxStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  color: 'text.primary',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const userLevels = [
  'USER',
  'MANAGER',
  'SUPER MANAGER',
  'ADMINISTRATOR',
];

export enum UserLevel {
  user = 1,
  manager = 2,
  superManager = 3,
  administrator = 4
}

export enum AdrPos {
  CNR = 'CNR',
  Esterni = 'Esterni',
  Provvisorio = 'Provvisorio',
  esterno = 'esterno',
  esternoNodoc = 'esterno-nodoc',
  admin = 'admin'
}

export enum UserOrigin {
  cards = 'cards',
  external = 'external' 
}

export const vlanNames = {
  '102': 'ISIB',
  '104': 'ITC',
  '105': 'IGG',
  '106': 'ISP',
  '112': 'ICIS',
  '114': 'IENI',
  '115': 'IRPI',
  '116': 'ADR',
  '117': 'USP',
  '119': 'ISAC'
}

export const multipleVLanAffiliations = [
  'ICMATE'
];

export const multipleVLanAffiliationMap = {
  'ICMATE': ['112', '114']
}
