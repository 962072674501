import DesktopWindowsRoundedIcon from '@mui/icons-material/DesktopWindowsRounded';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Modal from '@mui/material/Modal';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { apiBaseUrl, modalBoxStyle, secHeaderKey } from '../consts';
import { DeviceData } from '../data';
import { activeSessionState } from '../state';
import ConfirmationDialog from './ConfirmationDialog';
import WaitLoading from './WaitLoading';

const boxWidth = 310;
const boxHeight = 120;

function WaitSkeleton() {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDisplay(true);
    }, 1000);
  }, []);

  if (display) {
    return (
      <Box>
        <Stack direction="row">
          <Card sx={{ minWidth: boxWidth, m: 1 }} elevation={3}>
            <CardContent sx={{ minHeight: boxHeight }}>
              <Skeleton animation="wave" variant='text' />
              <Skeleton animation="wave" variant='text' />
            </CardContent>
            <CardActions>
              <br />
            </CardActions>
          </Card>
          <Card sx={{ minWidth: boxWidth, m: 1 }} elevation={3}>
            <CardContent sx={{ minHeight: boxHeight }}>
              <Skeleton animation="wave" variant='text' />
              <Skeleton animation="wave" variant='text' />
            </CardContent>
            <CardActions>
              <br />
            </CardActions>
          </Card>
        </Stack>
      </Box>
    );  
  } else {
    return (<></>);
  }
}

interface WolDeviceProps {
  device: DeviceData
  onClick: (device: DeviceData) => void;
}

const WolDevice = (props: WolDeviceProps) => {
  const performWOL = () => {
    props.onClick(props.device);
  }

  return (
    <>
      <Card sx={{ width: '100%', my: 1, display: { md: 'none'  } }} elevation={3}>
        <CardContent>
          <Typography component='h6' variant='h6' mb={1}>
            {props.device.note}
          </Typography>
          <pre style={{ paddingTop: 0, paddingBottom: 0, display: 'inline' }}>
            { props.device.macaddr }
          </pre>
        </CardContent>
        <CardActions>
          <Button size='small'
            color='primary'
            onClick={performWOL} >
            <PowerSettingsNewIcon sx={{mr: 1}} /> Wake On LAN
          </Button>
        </CardActions>
      </Card>      
      <Card sx={{ minWidth: boxWidth, m: 1, display: { xs: 'none', md: 'block' } }} elevation={3}>
        <CardContent sx={{ minHeight: boxHeight }}>
          <Typography component='h6' variant='h6' mb={1}>
            <DesktopWindowsRoundedIcon sx={{ mr: 2, verticalAlign:'middle' }} />
            {props.device.note}
          </Typography>
          <pre style={{ paddingTop: 0, paddingBottom: 0, display: 'inline' }}>
            { props.device.macaddr }
          </pre>
        </CardContent>
        <CardActions>
          <Button size='small'
            color='primary'
            onClick={performWOL} >
            <PowerSettingsNewIcon sx={{mr: 1}} /> Wake On LAN
          </Button>
        </CardActions>
      </Card>
    </>
  );
}

export default function MyWolDevices() {
  const navigate = useNavigate();

  const sessionData = useRecoilValue(activeSessionState);
  const [devices, setDevices] = useState<DeviceData[] | null>(null);

  const [executeLoadDevices, setExecuteLoadDevices] = useState(true);
  const [executeWol, setExecuteWol] = useState(false);
  
  const [wolMac, setWolMac] = useState('');
  const [wolConfirmationOpen, setWolConfirmationOpen] = useState(false);
  const [wolConfirmationMessage, setWolConfirmationMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [pageResult, setPageResult] = useState<{
    title: string;
    message: string;
    onClose: () => void
  } | null>(null);

  const handlePerformWOL = (device: DeviceData) => {
    const mac = device.macaddr;
    const desc = device.note;
    setWolConfirmationMessage(`Are you sure you want to wake device  ${mac}  (${desc}) ?`);
    setWolConfirmationOpen(true);
    setWolMac(mac);
  }

  const handleWolConfirmationDialogClose = (confirmed: boolean) => {
    setWolConfirmationOpen(false);
    if (confirmed) {
      setExecuteWol(true);
    }
  }

  useEffect(() => {
    if (executeLoadDevices) {
      setTimeout(() => {
        fetch(`${apiBaseUrl}/my-devices.php`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            [secHeaderKey]: sessionData.sec
          }
        })
          .then(response => {
            if (response.status === 401) {
              navigate('/login');
            } else if (response.status !== 200) {
              throw Error(`${response.status} - ${response.statusText}`);
            } else {
              return response.json();
            }
          })
          .then(
            response => {
              if (response && response.success) {
                let devices: DeviceData[] = response.devices;
                devices = devices.filter(device => device.wol === '1');
                if (devices.length > 3) {
                  devices = [];
                }
                setDevices(devices);
              } else {
                console.error('Error loading my-devices.');
              }
            });
      }, 0);
      setExecuteLoadDevices(false);
    }

    if (executeWol) {
      setTimeout(() => {
        fetch(`${apiBaseUrl}/my-wol.php`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            [secHeaderKey]: sessionData.sec
          },
          body: JSON.stringify({ macaddr: wolMac })
        })
          .then(response => {
            setIsLoading(false);
            if (response.status === 401) {
              navigate('/login');
            } else if (response.status !== 200) {
              throw Error(`${response.status} - ${response.statusText}`);
            } else {
              return response.json();
            }
          }, reject => {
            setIsLoading(false);
            setPageResult({
              title: 'Error',
              message: `An error has occurred: ${reject}`,
              onClose: () => setPageResult(null)
            });
          })
          .then(
            response => {
              if (response.success) {
                setPageResult({
                  title: 'Success',
                  message: `Wake On Lan request successfully sent.`,
                  onClose: () => setPageResult(null)
                });
              } else {
                if (typeof response.message === 'string') {
                  setPageResult({
                    title: 'Error',
                    message: `An error has occurred: ${response.message}`,
                    onClose: () => setPageResult(null)
                  });
                } else {
                  setPageResult({
                    title: 'Error',
                    message: `An unknown error has occurred.`,
                    onClose: () => setPageResult(null)
                  });
                }
                console.error('Resend my-guest password error', response);
              }
            });
      }, 1000);
      setExecuteWol(false);
      setIsLoading(true);
    }
  }, [ executeLoadDevices, executeWol ]);      

  return (
    <>
      {devices === null && <WaitSkeleton />}
      {devices !== null && devices.length > 0 && (<Box>
        <Stack direction="row" sx={{ flexWrap: 'wrap', my: 3 }}>
          { devices.map(device => (<WolDevice key={device.macaddr} device={device} onClick={handlePerformWOL} />)) }
        </Stack>
      </Box>)}

      <Modal
        open={pageResult !== null}
        onClose={pageResult?.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={modalBoxStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            { pageResult?.title }
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          { pageResult?.message }
          </Typography>
          <Button aria-label='continue' variant='contained' onClick={pageResult?.onClose} sx={{mt: 3}}>
            <PlayArrowIcon sx={{ mr: 2 }} /> Continue
          </Button>
        </Box>
      </Modal> 

      {isLoading && <WaitLoading />}

      <ConfirmationDialog
        open={wolConfirmationOpen} title='Wake On Lan' message={wolConfirmationMessage}
        onClose={handleWolConfirmationDialogClose} />
    </>
  );
}