import BackspaceIcon from '@mui/icons-material/Backspace';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { apiBaseUrl, modalBoxStyle, secHeaderKey } from '../consts';
import { DeviceData } from '../data';
import { activeSessionState } from '../state';
import ConfirmationDialog from './ConfirmationDialog';
import { GridBool } from './GridBool';
import WaitLoading from './WaitLoading';

interface WolButtonProps {
  id: string;
  hasFocus: boolean;
  disabled: boolean;
  onClick: (id: string) => void;
}

const WolButton = (props: WolButtonProps) => {
  const performWOL = () => {
    props.onClick(props.id);
  }

  const style = props.disabled ? { opacity: 0.3 } : {};

  return (
    <Button aria-label='Wake On Lan'
      variant='outlined'
      size='small'
      color='secondary'
      disabled={props.disabled}
      sx={style}
      tabIndex={props.hasFocus ? 0 : -1} onClick={performWOL} >
      <PowerSettingsNewIcon sx={{mr: 1}} /> Wake On LAN
    </Button>
  );
}

export default function UserDevices({
  devices,
  userName,
  gridHeight,
  canFilter
}: {
  devices: DeviceData[],
  userName: string,
  gridHeight: number,
  canFilter: boolean
}) {
  const navigate = useNavigate();

  const sessionData = useRecoilValue(activeSessionState);

  const [executeWol, setExecuteWol] = useState(false);
  
  const [wolMac, setWolMac] = useState('');
  const [wolConfirmationOpen, setWolConfirmationOpen] = useState(false);
  const [wolConfirmationMessage, setWolConfirmationMessage] = useState('');

  const [txtFilter, setTxtFilter] = useState('');

  const [oldCanFilter, setOldCanFilter] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [pageResult, setPageResult] = useState<{
    title: string;
    message: string;
    onClose: () => void
  } | null>(null);

  if (canFilter !== oldCanFilter) {
    setOldCanFilter(canFilter);
    if (canFilter) {
      setTimeout(() => {
        document.getElementById('txtFilter')!.focus();
      }, 100);
    } else {
      setTxtFilter('');
    }
  }

  const handlePerformWOL = (id: string) => {
    const device = devices.find(device => device.id === id);
    const mac = device!.macaddr;
    const desc = device!.note;
    setWolConfirmationMessage(`Are you sure you want to wake device  ${mac}  (${desc}) ?`);
    setWolConfirmationOpen(true);
    setWolMac(mac);
  }

  const handleWolConfirmationDialogClose = (confirmed: boolean) => {
    setWolConfirmationOpen(false);
    if (confirmed) {
      setExecuteWol(true);
    }
  }

  const handleTxtFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTxtFilter(event.target.value);
  };

  
  const handleResetFilters = () => {
    setTxtFilter('');
  };

  let filteredDevices = devices; 
  if (canFilter) {
    if (txtFilter !== '') {
      const keys = txtFilter.toLowerCase().split(/(\s+)/).filter(s => s.trim() !== '');
      const totKeys = keys.length;
      if (totKeys > 0) {
        filteredDevices = devices.filter(device => {
          
          let totKeyFound = 0;
          for (let i = 0; i < totKeys; i++) {
            const key = keys[i];
            if (device.macaddr.toLowerCase().indexOf(key.replace(':', '-')) !== -1) {
              totKeyFound += 1;
              continue;
            }

            if (device.fqdn.toLowerCase().indexOf(key) !== -1) {
              totKeyFound += 1;
              continue;
            }

            if (device.note.toLowerCase().indexOf(key) !== -1) {
              totKeyFound += 1;
              continue;
            }
          }
          return totKeyFound === totKeys;
        });  
      } else {        
        filteredDevices = devices;  
      }
    } else {
      filteredDevices = devices;
    }
  }
  

  const cols: GridColDef[] = [
    { field: 'wol', headerName: '', width: 165, renderCell: (params: GridRenderCellParams<string>) => (
      <WolButton id={params.id.toString()} hasFocus={params.hasFocus} onClick={handlePerformWOL} disabled={params.row.wol !== '1'} />
    )},   
    { field: 'macaddr', headerName: 'MAC Address', width: 200, renderCell: (params: GridRenderCellParams<number>) => (
      <pre style={{ paddingTop: 0, paddingBottom: 0, display: 'inline' }}>{params.value}</pre>
    )},
    { field: 'fqdn', headerName: 'FQDN', width: 300 },
    { field: 'ismobile', headerName: 'Mobile', align:'center', headerAlign: 'center', width: 100, renderCell: (params: GridRenderCellParams<number>) => (
      <GridBool value={params.value}></GridBool>
    )},
    { field: 'ispersonal', headerName: 'Personal', align:'center', headerAlign: 'center', width: 100, renderCell: (params: GridRenderCellParams<number>) => (
      <GridBool value={params.value}></GridBool>
    )},
    { field: 'note', headerName: 'Description', width: 200, flex: 1, },
    { field: 'createdOn', headerName: 'Creation Time', width: 200 },
  ];

  useEffect(() => {
    if (executeWol) {
      setTimeout(() => {
        fetch(`${apiBaseUrl}/user-wol.php`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            [secHeaderKey]: sessionData.sec
          },
          body: JSON.stringify({ userName: userName, macaddr: wolMac })
        })
          .then(response => {
            setIsLoading(false);
            if (response.status === 401) {
              navigate('/login');
            } else if (response.status !== 200) {
              throw Error(`${response.status} - ${response.statusText}`);
            } else {
              return response.json();
            }
          }, reject => {
            setIsLoading(false);
            setPageResult({
              title: 'Error',
              message: `An error has occurred: ${reject}`,
              onClose: () => setPageResult(null)
            });
          })
          .then(
            response => {
              if (response.success) {
                setPageResult({
                  title: 'Success',
                  message: `Wake On Lan request successfully sent.`,
                  onClose: () => setPageResult(null)
                });
              } else {
                if (typeof response.message === 'string') {
                  setPageResult({
                    title: 'Error',
                    message: `An error has occurred: ${response.message}`,
                    onClose: () => setPageResult(null)
                  });
                } else {
                  setPageResult({
                    title: 'Error',
                    message: `An unknown error has occurred.`,
                    onClose: () => setPageResult(null)
                  });
                }
                console.error('Resend my-guest password error', response);
              }
            });
      }, 1000);
      setExecuteWol(false);
      setIsLoading(true);
    }
  }, [ executeWol ]);      

  return (
    <Paper sx={{mt: 3}}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Paper sx={{ p: 2, flexGrow: 1 }} elevation={3} > 
          <Typography component='p' variant='subtitle1' mt={0} mb={2}>
            Devices:
          </Typography>
          { canFilter && (
          <Box sx={{ display: 'flex', flexDirection: 'row'}}>
            <TextField id='txtFilter' label='MAC Address, FQDN or Description' variant='outlined' sx={{ mb: 2, flexGrow: 1 }}
            value={txtFilter} onChange={handleTxtFilterChange}
            />

            <Button aria-label='reset-filters' variant='text' sx={{ml: 2, mb: 2, display: { xs: 'none', sm: 'flex' }}} onClick={handleResetFilters}>
              <BackspaceIcon sx={{mr: 2}} /> Reset filters
            </Button> 
          </Box>
          )}
          <DataGrid rows={filteredDevices} columns={cols} initialState={{
            sorting: {
              sortModel: [{ field: 'createdOn', sort: 'desc' }],
            },
            }} sx={{ height: gridHeight }} />
        </Paper>
      </Box>

      <Modal
        open={pageResult !== null}
        onClose={pageResult?.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={modalBoxStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            { pageResult?.title }
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          { pageResult?.message }
          </Typography>
          <Button aria-label='continue' variant='contained' onClick={pageResult?.onClose} sx={{mt: 3}}>
            <PlayArrowIcon sx={{ mr: 2 }} /> Continue
          </Button>
        </Box>
      </Modal> 

      {isLoading && <WaitLoading />}

      <ConfirmationDialog
        open={wolConfirmationOpen} title='Wake On Lan' message={wolConfirmationMessage}
        onClose={handleWolConfirmationDialogClose} />
    </Paper>  
  );
}