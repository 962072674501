import BadgeIcon from '@mui/icons-material/Badge';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import ConsolePage from '../../components/ConsolePage';
import ConsolePageBody from '../../components/ConsolePageBody';
import Copyright from '../../components/Copyright';
import MyWolDevices from '../../components/MyWolDevices';
import { activeSessionState, logoutConfirmationOpenState, permissionSelector } from '../../state';
import { adaptAffiliation, adaptUserType, formatInfo, formatUserLevel, getUserLevel, getVlanDescName } from '../../utils/adapters';

export default function Dashboard() {
  const navigate = useNavigate();
  
  const sessionData = useRecoilValue(activeSessionState);
  const permissionData = useRecoilValue(permissionSelector);

  const setLogoutConfirmationOpen = useSetRecoilState(logoutConfirmationOpenState);

  const handleMyDevicesClick = () => {
    navigate('/console/my-devices');
  };

  const handleMyGuestsClick = () => {
    navigate('/console/my-guests');
  };

  const handleChangePasswordClick = () => {
    navigate('/console/change-password');
  };

  const handleLogoutClick = () => {
    setLogoutConfirmationOpen(true);
  };

  return (
    <ConsolePage title='Dashboard'>
      <ConsolePageBody>
        <Grid container spacing={2} component='div'>
          <Grid item xs={12} sm={7} md={8}>

            <Box mb={3} sx={{ display: { md: 'none' } }}>
              <Typography component='h1' variant='h4' >
                { sessionData.userData.displayName }
              </Typography>
              <Typography component='h3' variant='h5' color='secondary'>
                { sessionData.userData.userName }
              </Typography>    
              
              <Typography component='h4' variant='h6' sx={{ mt:5, mb: 4 }}>
                Welcome to the new Network Access Managmenent Console 
              </Typography>
            </Box>

            <Box mb={3} sx={{ display: { xs: 'none', md: 'block' } }}>
              <Typography component='h1' variant='h1' >
                { sessionData.userData.displayName }
              </Typography>
              <Typography component='h3' variant='h4' color='secondary'>
                { sessionData.userData.userName }
              </Typography>    
              
              <Typography component='h4' variant='h4' sx={{ mt:5, mb: 4 }}>
                Welcome to the new Network Access Managmenent Console 
              </Typography>
            </Box>

            <MyWolDevices />
                
          </Grid>
          <Grid item xs={12} sm={5} md={4}>
          
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography component='p' variant='h5' mb={3}>
                <BadgeIcon color='primary' fontSize='large'
                  sx={{
                    mr: 3, verticalAlign: 'text-bottom',
                    display: { xs: 'none', md: 'none', lg: 'inline' }
                  }} />
                Account Information
              </Typography>        
              
              <Typography component='p' variant='body2'>
                Phone:
              </Typography>
              <Typography component='p' variant='body1'>
                <strong>{ formatInfo(sessionData.userData.phone) }</strong>
              </Typography>

              <Divider sx={{ my: 2 }} />

              <Typography component='p' variant='body2'>
                Email:
              </Typography>
              <Typography component='p' variant='body1'>
                <strong>{ formatInfo(sessionData.userData.email) }</strong>
              </Typography>
          
              <Divider sx={{ my: 2 }} />
              
             
              <Typography component='p' variant='body2'>
                Affiliation:
              </Typography>
              <Typography component='p' variant='body1'>
                <strong>{ formatInfo(adaptAffiliation(sessionData.userData.affiliation)) }</strong>
              </Typography>

              {permissionData.canViewTechincalData && (
                <>
                  <Divider sx={{ my: 2 }} />

                  <Typography component='p' variant='body2'>
                    Type:
                  </Typography>
                  <Typography component='p' variant='body1'>
                    <strong>{ formatInfo(adaptUserType(sessionData.userData.userType)) }</strong>
                  </Typography>
              
                  <Divider sx={{ my: 2 }} />
                  
                  <Typography component='p' variant='body2'>
                    Level:
                  </Typography>
                  <Typography component='p' variant='body1'>
                    <strong>{ formatInfo(formatUserLevel(getUserLevel(sessionData.userData))) }</strong>
                  </Typography>

                  {permissionData.canManageMultipleVLans && (<>
                    <Divider sx={{ my: 2 }} />
                  
                    <Typography component='p' variant='body2'>
                      VLAN:
                    </Typography>
                    <Typography component='p' variant='body1'>
                      <strong>{getVlanDescName(sessionData.userData.vlan)}</strong>
                    </Typography>
                  </>)}
                </>
              ) }
            </Paper>

          </Grid>
        </Grid>   
      </ConsolePageBody>
      <Copyright sx={{ pt: 4 }} />
    </ConsolePage>
  );
}