import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DevicesIcon from '@mui/icons-material/Devices';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PasswordIcon from '@mui/icons-material/Password';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { pink } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { activeSessionState, logoutConfirmationOpenState, permissionSelector } from '../../state';
import { getInitials } from '../../utils/string';

const drawerWidth = 280;
const toolbarHeight = 70;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

function UserManager() {
  const menuId = 'account-menu';
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const sessionData = useRecoilValue(activeSessionState);
  const avatarInitials = getInitials(sessionData.userData.displayName)
  const permissionData = useRecoilValue(permissionSelector);

  const setLogoutConfirmationOpen = useSetRecoilState(logoutConfirmationOpenState);

  const handleUserMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setAnchorEl(null);
  };

  const gotoDashboard = () => {
    handleUserMenuClose();
    navigate('/console');
  }

  const gotoMyGuests = () => {
    handleUserMenuClose();
    navigate('/console/my-guests');
  };

  const gotoMyDevices = () => {
    handleUserMenuClose();
    navigate('/console/my-devices');
  };

  const gotoChangePassword = () => {
    handleUserMenuClose();    
    navigate('/console/change-password');
  };

  const logout = () => {
    handleUserMenuClose();
    setLogoutConfirmationOpen(true);
  };

  return (
    <>   
      <Box sx={{
        display: {
          xs: 'none',
          md: 'flex'
      }, flexDirection: 'column' }}>
        <Typography variant='h6' mr={5}>
        { sessionData.userData.displayName }
        </Typography>
        <Typography variant='body2' mr={5}>
        { sessionData.userData.userName }
        </Typography>  
      </Box>
      <Tooltip title='Account settings'>  
        <IconButton
          size='large'
          edge='end'
          aria-label='account of current user'
          aria-controls={menuId}
          aria-haspopup='true'
          onClick={handleUserMenuOpen}
          color='inherit'
        >
          <Avatar sx={{ width: 48, height: 48, bgcolor: pink[500] }}>{ avatarInitials }</Avatar>
        </IconButton>
      </Tooltip>  
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleUserMenuClose}
      >
        <MenuItem onClick={gotoDashboard}>
          <ListItemIcon>
            <DashboardIcon fontSize='small' />
            </ListItemIcon>
            Dashboard
        </MenuItem>  
        {permissionData.canManageOwnGuests && (
          <MenuItem onClick={gotoMyGuests}>
          <ListItemIcon>
            <SupervisorAccountOutlinedIcon fontSize='small' />
            </ListItemIcon>
            My Guests
        </MenuItem>   
        )}        
        <MenuItem onClick={gotoMyDevices}>
          <ListItemIcon>
            <DevicesIcon fontSize='small' />
            </ListItemIcon>
            My Devices
        </MenuItem>        
        <MenuItem onClick={gotoChangePassword}>
          <ListItemIcon>
            <PasswordIcon fontSize='small' />
          </ListItemIcon>
          Change Password
        </MenuItem>
        <Divider />
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIcon fontSize='small' />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}


function UserAppBar() {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <UserManager />
    </Box>
  );
}

const personalNavPL = 6;

export default function Console() {
  const navigate = useNavigate();

  const drawerOpenMQM = useMediaQuery('(min-width:1024px)');
  const [drawerOpen, setDrawerOpen] = React.useState(drawerOpenMQM);

  const permissionData = useRecoilValue(permissionSelector);
  const [logoutConfirmationOpen, setLogoutConfirmationOpen] = useRecoilState(logoutConfirmationOpenState);

  const adjustDrawer = () => {
    if(!drawerOpenMQM && drawerOpen) {
      setDrawerOpen(false);
    }
  }

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const gotoDashboard = () => {
    navigate('/console');
    adjustDrawer();
  }

  const gotoMyGuests = () => {
    navigate('/console/my-guests');
    adjustDrawer();
  };

  const gotoMyDevices = () => {
    navigate('/console/my-devices');
    adjustDrawer();
  };

  const gotoChangePassword = () => {
    navigate('/console/change-password');
    adjustDrawer();
  };

  const gotoUsers = () => {
    localStorage.removeItem('users.last.userTypeFilter');
    localStorage.removeItem('users.last.affiliationFilter');
    localStorage.removeItem('users.last.levelFilter');
    localStorage.removeItem('users.last.txtFilter');

    navigate('/console/users');
    adjustDrawer();
  }

  const gotoDevices = () => {
    navigate('/console/devices');
    adjustDrawer();
  }

  const logout = () => {
    setLogoutConfirmationOpen(true);
    adjustDrawer();
  };

  const handleLogoutConfirmationDialogClose = (confirmed: boolean) => {
    setLogoutConfirmationOpen(false);
    if (confirmed) {
      navigate('/logout');
    }
  }

  return (
    <>
    <Box sx={{ display: 'flex' }}>
      <AppBar position='absolute' open={drawerOpen} color='primary' enableColorOnDark sx={{pr: 0}}>
        <Toolbar>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(drawerOpen && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            <Typography component='h1' variant='h6' color='inherit' noWrap sx={{ display: { xs: 'block', md: 'none' } }}>
              <Box component='span' onClick={gotoDashboard} sx={{ cursor: 'pointer' }}>
                AdRPD / NAM                  
              </Box>
            </Typography>            
              
            <Typography component='h1' variant='h5' color='inherit' noWrap sx={{ display: { xs: 'none', md: 'block' } }}>
              <Box component='span' onClick={gotoDashboard} sx={{ cursor: 'pointer' }}>
                Network Access Management                  
              </Box>
            </Typography>
            <Typography component='h2' variant='subtitle1' color='inherit' noWrap sx={{ display: { xs: 'none', md: 'block' } }}>
              <Box component='span' onClick={gotoDashboard} sx={{ cursor: 'pointer' }}>
                CNR - Area territoriale di Ricerca di Padova
              </Box>
            </Typography>          
          </Box>

          <UserAppBar />                   
        </Toolbar>
      </AppBar>
      <Drawer variant='permanent' open={drawerOpen}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
            height: toolbarHeight
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component='nav'>
          <ListItemButton onClick={gotoDashboard}>
            <ListItemIcon>
              <AdminPanelSettingsOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary='Personal Area' />
          </ListItemButton>

          <Collapse in={drawerOpen} timeout="auto" unmountOnExit>
            <List component='div'>

              <ListItemButton onClick={gotoDashboard} sx={{ pl: personalNavPL }}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary='Dashboard' />
              </ListItemButton>    
                
              {permissionData.canManageOwnGuests && (<ListItemButton onClick={gotoMyGuests} sx={{ pl: personalNavPL }}>
                <ListItemIcon>
                  <SupervisorAccountOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary='My Guests' />
              </ListItemButton>)}
                
              <ListItemButton onClick={gotoMyDevices} sx={{ pl: personalNavPL }}>
                <ListItemIcon>
                  <DevicesIcon />
                </ListItemIcon>
                <ListItemText primary='My Devices' />
              </ListItemButton>

              <ListItemButton onClick={gotoChangePassword} sx={{ pl: personalNavPL }}>
                <ListItemIcon>
                  <PasswordIcon />
                </ListItemIcon>
                <ListItemText primary='Change Password' />
              </ListItemButton>

            </List>
          </Collapse>
            
            {permissionData.canManageUsers && (
            <>
                <Divider />
                
              <ListItemButton onClick={gotoUsers}>
                <ListItemIcon>
                  <BadgeOutlinedIcon  />
                </ListItemIcon>
                <ListItemText primary='Users' />
                </ListItemButton>
                
              { false && (
              <ListItemButton onClick={gotoDevices}>
                <ListItemIcon>
                  <LanOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary='Devices' />
                  </ListItemButton>
                  )}
            </>
          )}
          
          <Divider />
            
          <ListItemButton onClick={logout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary='Logout' />
          </ListItemButton>            
        </List>
      </Drawer>
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          pt: `${toolbarHeight}px`,
          overflow: 'auto',
        }}
      >
        <Outlet />
      </Box>
    </Box>
      <ConfirmationDialog
        open={logoutConfirmationOpen} title='LOGOUT' message='Are you sure you want to exit ?' 
        onClose={handleLogoutConfirmationDialogClose} />
      </>
  );
}
