import { atom, selector } from 'recoil';
import { multipleVLanAffiliations } from './consts';
import { UserData } from './data';

export const logoutConfirmationOpenState = atom({
  key: 'logoutConfirmationOpenState',
  default: false,
});

export interface SessionData {
  readonly sec: string;
  readonly userData: UserData;
  readonly enablePasswordPolicy: boolean;
}

export const sessionState = atom<SessionData | null>({
  key: 'sessionState',
  default: null,
});

export interface PermissionData {
  readonly canViewTechincalData: boolean;
  readonly canManageOwnGuests: boolean;
  readonly canManageUsers: boolean;
  readonly canManageAllUsers: boolean;
  readonly canManageMultipleVLans: boolean;
  readonly canDownloadDocId: boolean;
  readonly canCreateExternalNoDocUser: boolean;
}

export const activeSessionState = selector<SessionData>({
  key: 'activeSessionState',
  get: ({get}) => {
    const sessionData = get(sessionState);
    return sessionData!;
  }
});

export const permissionSelector = selector<PermissionData>({
  key: 'permissionSelector',
  get: ({get}) => {
    let canViewTechincalData = false;
    let canManageOwnGuests = false;
    let canManageUsers = false;
    let canManageAllUsers = false;
    let canManageMultipleVLans = false;
    let canDownloadDocId = false;
    let canCreateExternalNoDocUser = false;
    
    const sessionData = get(sessionState);
    
    if (sessionData) {
      if (sessionData.userData.canMakeGuest) {
        canManageOwnGuests = true;
      }  
  
      if (sessionData.userData.isManager) {
        canViewTechincalData = true;
        canManageUsers = true;
        canManageMultipleVLans = multipleVLanAffiliations.indexOf(sessionData.userData.affiliation.toUpperCase()) !== -1;
      }  

      if (sessionData.userData.isSuperManager) {
        canViewTechincalData = true;
        canManageUsers = true;
        canManageAllUsers = true;
        canManageMultipleVLans = true;
        canDownloadDocId = true;
        canCreateExternalNoDocUser = true;
      }

      if (sessionData.userData.isAdmin) {
        canViewTechincalData = true;
        canManageUsers = true;
        canManageAllUsers = true;
        canManageMultipleVLans = true;
        canDownloadDocId = true;
        canCreateExternalNoDocUser = true;
      }
    }   

    return {
      canViewTechincalData,
      canManageOwnGuests,
      canManageUsers,
      canManageAllUsers,
      canManageMultipleVLans,
      canDownloadDocId,
      canCreateExternalNoDocUser,
    };
  }
});